var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sort-play home-page-video-style",class:{ 'home-page-video-style': true }},[_c('EBackTop',{attrs:{"background":'transparent',"open-fallback":true,"color":'#fff',"placeholder":false},scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.showOpr)?_c('img',{attrs:{"src":require("@/assets/imgs/short-video/search.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('/search')}}}):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"play-box",attrs:{"id":'short-play'}}),_c('div',{staticClass:"bg-img"}),_c('div',{ref:"ShortVideoPage",staticClass:"swiper-container",attrs:{"id":"ShortVideoPage"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.virtualData.slides),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide",style:({
          top: `${_vm.virtualData.offset}px`,
          backgroundColor: _vm.slideChangeTransition ? '#000' : 'transparent'
        })},[_c('div',{staticClass:"slide-mark",on:{"click":_vm.handleClickSlideMark}},[_c('transition',{attrs:{"name":"van-fade"}},[(!_vm.showVolume)?_c('div',{staticClass:"volume-tip",on:{"click":function($event){$event.stopPropagation();return _vm.clickCloseVolume.apply(null, arguments)}}},[_c('van-icon',{attrs:{"name":"volume-o"}}),_c('span',[_vm._v("解除静音")])],1):_vm._e()]),_c('div',{staticClass:"right-icon"},[(_vm.showOpr)?[_c('div',{staticClass:"right-icon-likes"},[_c('van-icon',{style:({ fontSize: '30px', color: _vm.itemVideoInfo.isCollect ? 'red' : '#fff' }),attrs:{"name":"like"},on:{"click":function($event){$event.stopPropagation();return _vm.addLike(_vm.itemVideoInfo, 1)}}}),_c('span',[_vm._v(_vm._s(_vm._f("numberFilter")(_vm.itemVideoInfo.collects)))])],1),_c('div',{staticClass:"right-icon-comment",on:{"click":function($event){_vm.showCommentPanel = true}}},[_c('van-icon',{attrs:{"color":"#fff","size":"30","name":require('@/assets/imgs/short-video/comment.png')}}),_c('p',[_vm._v(_vm._s(_vm.itemVideoInfo.comments))])],1),_c('div',{staticClass:"right-icon-share",on:{"click":function($event){$event.stopPropagation();_vm.showCommunityShare = true}}},[_c('img',{attrs:{"src":require("@/assets/imgs/play/share1.png"),"alt":""}}),_c('span',[_vm._v("转发")])])]:_vm._e(),_c('div',{staticClass:"right-icon-fold"},[(_vm.showOpr)?_c('img',{attrs:{"src":require("@/assets/imgs/short-video/show_eye.png"),"alt":""},on:{"click":function($event){_vm.showOpr = false}}}):_c('img',{attrs:{"src":require("@/assets/imgs/short-video/hidden_eye.png"),"alt":""},on:{"click":function($event){_vm.showOpr = true}}})])],2),(_vm.showOpr)?_c('div',{staticClass:"video-info"},[_c('div',[(_vm.itemVideoInfo.price && !_vm.itemVideoInfo.isBuy)?_c('div',{staticClass:"pay-coin",on:{"click":_vm.skipPreview}},[_vm._v(" "+_vm._s(_vm.videoPrice)+"金币解锁 ")]):(
                  !_vm.itemVideoInfo.price && _vm.itemVideoInfo.payType === 1 && !_vm.itemVideoInfo.isBuy && !_vm.isVipMember
                )?_c('div',{staticClass:"buy-vip",on:{"click":_vm.skipPreview}},[_vm._v(" 开通会员 畅享完整内容 ")]):_vm._e()]),_c('div',{staticClass:"tags-list"},_vm._l((_vm.itemVideoInfo.tagList),function(sitem){return _c('span',{key:sitem,on:{"click":function($event){$event.stopPropagation();return _vm.toTagList(sitem)}}},[_vm._v("#"+_vm._s(sitem.name))])}),0),_c('div',{staticClass:"video-title"},[_vm._v(" "+_vm._s(_vm.itemVideoInfo.title)+" ")])]):_vm._e()],1)])}),0)]),_c('van-popup',{style:({ height: '9rem' }),attrs:{"round":"","position":"bottom"},on:{"click-overlay":_vm.closePanel},model:{value:(_vm.showCommentPanel),callback:function ($$v) {_vm.showCommentPanel=$$v},expression:"showCommentPanel"}},[(_vm.showCommentPanel)?_c('Comment',{staticClass:"comment-box",attrs:{"publisher":_vm.itemVideoInfo.publisher,"object-id":_vm.itemVideoInfo.id,"objectype":1,"show-opr":true}}):_vm._e()],1),_c('NoticeVipPop',{on:{"showVip":_vm.goToVip},model:{value:(_vm.showVipPop),callback:function ($$v) {_vm.showVipPop=$$v},expression:"showVipPop"}}),_c('NoticeCoinPop',{on:{"showCoin":_vm.goToCoin},model:{value:(_vm.showCoinPop),callback:function ($$v) {_vm.showCoinPop=$$v},expression:"showCoinPop"}}),_c('ConfirmCoinPop',{attrs:{"movieTickets":_vm.user.movieTickets,"movieDiscount":_vm.itemVideoInfo.movieDiscount,"price":_vm.itemVideoInfo.price},on:{"confirm":_vm.payBtnFn},model:{value:(_vm.showConfirmCoinPop),callback:function ($$v) {_vm.showConfirmCoinPop=$$v},expression:"showConfirmCoinPop"}}),_c('SharePopup',{attrs:{"sharePopShow":_vm.showCommunityShare},on:{"close":e => {
        _vm.showCommunityShare = e
      }}}),_c('BuyVipPop',{ref:"BuyVipPop"}),_c('BuyGoldPop',{ref:"BuyGoldPop",attrs:{"videoInfo":_vm.itemVideoInfo}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }