<template>
  <div class="video-card-footer">
    <div class="title">
      {{ data.title }}
    </div>
    <div class="tags">
      <div>
        <div v-if="data.tagList && data.tagList.length > 0" @click.stop="clickTag(data.tagList[0])">
          {{ data.tagList[0].name }}
        </div>
        <!--        <div v-if="data.tagList && data.tagList.length > 0 && data.tagList[1]" @click.stop="clickTag(data.tagList[1])">-->
        <!--          {{ data.tagList[1].name }}-->
        <!--        </div>-->
      </div>
      <div class="play-time" v-if="!showMsg">
        <img class="playLogo" src="@/assets/imgs/home/playLogo1.png" alt="" />{{ data.watchTimes | numberFilter }}
      </div>
      <div class="play-time" v-else>
        <img src="@/assets/imgs/home/msg_icon.png" alt="" />{{ data.comments | numberFilter }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VideoCardFooter',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    showMsg: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickTag(item) {
      if (item.id) {
        this.$router.push({
          path: '/home/videoTagDetail',
          query: {
            id: item.id,
            name: item.name
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.video-card-footer {
  background: #f7f7f7;
  .title {
    margin: 0.08rem 0 0.18rem;
    width: 100%;
    height: 0.3rem;
    line-height: 1.4;
    padding: 0 0.16rem;

    font-size: 0.26rem;
    font-weight: 500;
    color: #333;
    @include textoverflow(1);
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.14rem 0.18rem 0.16rem;

    > div:first-child {
      display: flex;
      align-items: center;
      overflow-x: auto;

      > div {
        flex-shrink: 0;
        padding: 0 0.15rem;
        height: 0.34rem;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 0.22rem;
        background: #e8619d;
        border-radius: 0.06rem;
        margin-right: 0.02rem;
      }
    }

    .play-time {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      padding-left: 0.04rem;
      > img {
        width: 0.22rem;
        height: 0.22rem;
        margin-right: 0.06rem;
      }
      .playLogo {
        width: 0.26rem !important;
      }
    }
  }
}
</style>
