<!--
 * @Author:
 * @Mail:
 * @Date: 2024-01-31 19:51:05
 * @LastEditTime: 2024-01-31 20:44:02
 * @LastEditors: Please set LastEditors
 * @FilePath: /haoseH5/src/components/Home/homeSeach.vue
-->
<template>
  <div class="home-header">
    <div class="frontPage">
      <div class="search-input" @click="$router.push('/search')">
        <img src="@/assets/imgs/home/Search.png" alt class="searchIcon" />
        输入搜索文字
      </div>
    </div>
    <img src="@/assets/imgs/home/top_fatie.png" alt class="searchLogo" @click="onRelease" />
    <img src="@/assets/imgs/home/top_fuli.png" alt class="searchLogo" @click="$router.push(`/Mine/myShouyi?type=1`)" />
  </div>
</template>

<script>
export default {
  name: 'HomeSearch',
  mounted() {},
  methods: {
    toTagList() {
      this.$emit('clickHeader')
    },
    onRelease() {
      this.$emit('onRelease')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-header {
  padding: 0 0.32rem;
  height: 0.88rem;
  background-color: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .frontPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 5.2rem;
    .search-input {
      background: rgba(217, 217, 217, 0.3);
      width: 7.2rem;
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 0.7rem;
      color: #939496;
      font-size: 0.28rem;
      padding-left: 0.21rem;
      .searchIcon {
        width: 0.32rem;
        height: 0.32rem;
      }
    }
  }
  .btn {
    font-size: 0.28rem;
    border-radius: 0.08rem;
    background: #e8619d;
    padding: 0.05rem 0.1rem;
    margin-left: 0.1rem;
    color: #fff;
  }
  .searchLogo {
    width: 0.6rem;
    height: 0.6rem;
    // margin-left: 0.34rem;
  }
}
</style>
