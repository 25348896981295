<template>
  <div class="vip-main">
    <div class="vip_main_top">
      <EDecryptImg
        :imgCDN="imgCDN"
        class="vip_main_top_bg"
        :imgURL="activeItem.userVipImage ? activeItem.userVipImage : ''"
      >
      </EDecryptImg>
      <EBackTop title="会员中心" background="transparent" color="#fff" @click-right="$router.push('/Mine/recharge')">
        <slot slot="right" name="right">
          <div class="right-txt">充值记录</div>
        </slot>
      </EBackTop>
      <!-- 会员卡轮播 -->
      <EBswiper
        class="swiper_main"
        :list="cardInfoList"
        :centeredSlides="true"
        :EADconfig="EADconfig"
        :activeIndex="activeIndex"
        :imgCDN="imgCDN"
        :keyName="'userVipImage'"
        :slidesPerView="1.3"
        :spaceBetween="20"
        @changeSlide="changeSlide"
      >
        <template #default="scope">
          <!-- 活动倒计时 -->
          <div v-if="scope.item.isNewUserCard" class="card_time">
            <p
              v-if="hourC < 0"
              class="over_txt"
              :style="{ backgroundColor: activeItem.countdownBgColor, color: activeItem.countdownTextColor }"
            >
              已结束
            </p>
            <van-count-down v-else :time="hourT" millisecond>
              <template #default="timeData">
                <span
                  class="advertiseTimeOut-block"
                  :style="{
                    backgroundColor: activeItem.countdownBgColor,
                    color: activeItem.countdownTextColor,
                    borderColor: activeItem.countdownBorderColor
                  }"
                  >{{ timeData.hours >= 10 ? timeData.hours : `0${timeData.hours}` }}</span
                >
                <span class="advertiseTimeOut-txt" :style="{ color: activeItem.countdownColonColor }">:</span>
                <span
                  class="advertiseTimeOut-block"
                  :style="{
                    backgroundColor: activeItem.countdownBgColor,
                    color: activeItem.countdownTextColor,
                    borderColor: activeItem.countdownBorderColor
                  }"
                  >{{ timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}` }}</span
                >
                <span class="advertiseTimeOut-txt" :style="{ color: activeItem.countdownColonColor }">:</span>
                <span
                  class="advertiseTimeOut-block"
                  :style="{
                    backgroundColor: activeItem.countdownBgColor,
                    color: activeItem.countdownTextColor,
                    borderColor: activeItem.countdownBorderColor
                  }"
                  >{{ timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}` }}</span
                >
              </template>
            </van-count-down>
          </div>
        </template>
      </EBswiper>
    </div>

    <!-- 权益 -->
    <div v-if="activeItem.rightDataList && activeItem.rightDataList.length" class="active-card-right-box">
      <img src="@/assets/imgs/mine/vip_txt_title.png" alt="" />
      <div class="right_box_sub">
        <ul class="active-card-right">
          <li v-for="(item, index) in activeItem.rightDataList" :key="index" class="card_item">
            <EDecryptImg :imgCDN="imgCDN" class="card_photo" :imgURL="item.image"> </EDecryptImg>
            <div class="card_info">
              <span> {{ item.name }}</span>
              <p>{{ item.desc }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrapBtn">
      <div class="submit_btn" @click="openPop">
        {{ !activeItem.isBuy ? '立即开通' : '已开通' }}
      </div>
      <div class="foont">
        支付问题反馈，点击&nbsp;<span style="color: #e05e78" @click="$router.push('/Mine/setting/kfProblem')"
          >联系客服</span
        >
      </div>
    </div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="shoudBuy" position="bottom" :style="{ height: '50vh' }" class="submit-pop">
      <div class="title">请选择支付方式</div>
      <!-- <div class="price">
                <span class="price">已选择：{{ activeItem.title }}(¥{{ activeItem.money | changeGold }})</span>
            </div> -->
      <ul class="pay-list">
        <li
          v-for="(item, index) in activeItem.rchgType"
          :key="index"
          class="item"
          :class="{ active: item.type === payMode }"
          @click="fn_sel(item.type)"
        >
          <div v-if="item.type === 'alipay'" class="left">
            <img src="@/assets/imgs/mine/zfb2.png" alt="" />
            {{ item.typeName }}
          </div>
          <div v-if="item.type === 'wechat'" class="left">
            <img src="@/assets/imgs/mine/weChat2.png" alt="" />
            {{ item.typeName }}
          </div>
          <div v-if="item.type === 'overage'" class="left">
            <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
          </div>
          <div v-if="item.type === 'union'" class="left">
            <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
          </div>
          <div v-if="item.type === 'daichong'" class="left">
            <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
          </div>
          <div class="right">
            <div v-if="item.type == payMode" class="active"></div>
          </div>
        </li>
      </ul>
      <!-- <div class="tip-wrap">如有疑问，请咨询<span @click.stop="$router.push('/mine/setting/kf')">在线客服</span></div> -->
      <div class="tip-wrap">
        <p class="title_txt">付款提示：<br /></p>
        1、因超时支付无法到账，请重新发起。<br />
        2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单。<br />
      </div>
      <!-- 开通按钮 -->
      <div class="btn" @click="fn_submit">
        <div class="txt">确认支付</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { vip_list, recharge_sumbit } from 'api/home'
import { userInfo } from 'api/user'

export default {
  name: 'Vip',

  components: {
    EBswiper: () => import('@/components/web_base/component_base/EADSwiper/index.vue')
  },
  data() {
    return {
      list: {},
      message: [],
      active: 0,
      activeItem: {},
      shoudBuy: false,
      hasBuy: false,
      payMode: '',
      cardInfoList: [],
      checkActiveItem: 0,
      activeIndex: 0,
      EADconfig: {
        loop: true,
        speed: 100
      },
      hourT: 0,
      hourC: 0
    }
  },
  computed: {
    user({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  async created() {
    await this.getVipList()
    void this.getUserInfo()
    const minute = 1000 * 60
    const hour = minute * 60
    this.hourT = Date.parse(this.user.promotionExpiredAt) - new Date().getTime()
    this.hourC = (Date.parse(this.user.promotionExpiredAt) - new Date().getTime()) / hour
  },
  activated() {
    void this.getUserInfo()
  },
  methods: {
    // 切换会员卡
    changeSlide(realIndex) {
      this.activeItem = this.cardInfoList[realIndex]
      this.$forceUpdate()
    },
    // 选中会员卡
    checkItem(item, index) {
      this.checkActiveItem = item.id
      this.activeItem = item
    },

    // 时间差计算
    time(date) {
      const nowTiem = new Date().getTime()
      const newTime = Date.parse(date)
      const other = newTime - nowTiem
      if (other && other > 0) {
        return other
      } else {
        return false
      }
    },

    // 获取用户信息
    async getUserInfo() {
      const res = await userInfo()
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
      }
    },
    // 获取会员卡列表
    async getVipList() {
      try {
        const res = await vip_list()
        if (res.code === 200) {
          this.list = res.data
          this.cardInfoList = res.data[0].cardInfoList
          // this.message = res.data.list.message
          this.activeItem = this.list[0].cardInfoList[0]
          this.checkActiveItem = this.list[0].cardInfoList[0].id
          this.active = this.list[0].cardInfoList[0].id
          this.payMode = this.list[0].cardInfoList[0]?.rchgType[0]?.type
        } else {
          return this.$toast(res.tip || '请求错误，请稍后再试！')
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    openPop() {
      if (this.user.vipType == 11 && this.user.vipExpire > 0) {
        return this.$toast('您已成为商家卡用户，无法购买其他会员卡!')
      }
      if (this.activeItem.isNewUserCard && this.hourC < 0) {
        return this.$toast('当前会员卡必须在24小时内购买！')
      }
      if (!this.activeItem.isBuy) {
        this.shoudBuy = true
      }
    },
    // 选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 支付金币
    async fn_submit() {
      if (!this.payMode) return this.$toast('请选择支付通道！！！')
      this.$toast.loading({
        duration: 0,
        message: '跳转中请稍后'
      })
      const res = await recharge_sumbit({
        payAmount: this.activeItem.money,
        payMode: this.payMode,
        productId: this.activeItem.id,
        rchgUse: 1 // 1 会员卡 2金币 3游戏
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) {
          this.hasBuy = true
          this.$toast('购买成功！！！')
          this.shoudBuy = false
          this.getUserInfo()
        }
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
        this.$toast.clear()
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
      }
    },
    /* 利用a标签跳转safari浏览器机制 */
    downloadFileByTagA(fileUrl) {
      const TagA = document.createElement('a')
      TagA.href = fileUrl
      // TagA.target = '__blank';

      TagA.className = 'oInput'
      TagA.style.display = 'none'

      // 兼容ios safari浏览器
      const e = document.createEvent('MouseEvent')
      e.initEvent('click', false, false)
      TagA.dispatchEvent(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-main {
  // background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  height: 100vh;
  overflow-y: auto;

  .vip_main_top {
    height: 7.54rem;
    position: relative;

    .vip_main_top_bg {
      position: absolute;
      height: 100%;
      width: 100%;
      filter: blur(5px);

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 2;
        height: 100%;
        width: 100%;
      }
    }
  }

  :deep() {
    .right-txt {
      font-size: 0.28rem;
      color: #c8c8c8;
    }
  }
}

.swiper_main {
  margin-top: 0.36rem;
  height: 3.6rem !important;

  :deep() {
    .swiper-notification {
      display: none;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-slide {
      height: 2.7rem;
      position: relative;
      border-radius: 0.12rem;
      border: 1px solid #ffd099;

      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
        top: 0;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: inherit !important;
      }
    }

    .swiper-slide-active {
      height: 3.6rem;

      &::after {
        display: none;
      }
    }
  }

  .slide-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    background: #000;
  }

  .card_time {
    position: absolute;
    left: 0.36rem;
    bottom: 0.24rem;

    font-weight: 600;

    .over_txt {
      padding: 0.06rem 0.36rem;
      border-radius: 0.04rem;
    }

    .advertiseTimeOut-block {
      padding: 0.02rem 0.06rem;
      border: 1px solid;
    }

    .advertiseTimeOut-txt {
      margin: 0 0.07rem;
    }

    :deep() {
      .van-count-down {
      }
    }
  }
}

.user_info {
  width: 6.22rem;
  height: 1.28rem;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  margin-top: 0.3rem;

  .profile_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .vip_info {
    @include flexbox($jc: flex-start);
    z-index: 1;
    padding: 0.2rem 0 0 0.36rem;

    .md-mine-photo {
      width: 0.88rem;
      height: 0.88rem;
      flex-shrink: 0;
      margin-right: 0.16rem;
    }

    .right {
      position: relative;
      z-index: 1;

      .nickName {
        font-size: 0.32rem;
      }

      .title {
        color: rgba($color: #ffcf86, $alpha: 0.7);

        font-size: 0.24rem;

        span {
          color: $btnBg;
        }
      }
    }
  }
}

.card_list {
  margin-top: 0.52rem;
  width: 100%;
  @include flexbox($jc: flex-start);
  @include scrollbar-hide();
  overflow-y: auto;
  padding-left: 0.64rem;

  li {
    margin-right: 0.16rem;
    width: 2rem;
    height: 2.4rem;
    position: relative;
    border-radius: 0.12rem;
    border: 1px solid #535353;
    background: #373737;
    flex-shrink: 0;
    text-align: center;

    .desc {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.12rem 0rem;
      padding: 0.04rem 0.08rem;
      color: #fff;
      font-size: 0.2rem;
      max-width: 90%;
      @include textoverflow();
    }

    .name {
      margin-top: 0.6rem;
      font-size: 0.28rem;
      font-weight: 600;
      padding: 0 0.15rem;
    }

    .money {
      font-size: 0.45rem;
      font-weight: 700;
    }

    .pre_money {
      color: #fff;
      font-size: 0.28rem;
      font-weight: 400;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 0.05rem;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .active {
    border-radius: 0.12rem;
    border: 0.7px solid #ffcf86;
    background: linear-gradient(180deg, #171717 40.8%, #9c826b 113.75%);

    .money {
      color: #ffcf86;
    }
  }
}

.btn {
  z-index: 2;
  position: fixed;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.6rem;
  background: $gradientBg;
  width: 6.5rem;
  height: 0.9rem;
  flex-shrink: 0;
  color: #fff;

  .txt {
    font-size: 0.3rem;
    line-height: 0.9rem;
    text-align: center;
  }
}

.submit-pop {
  border-radius: 0.48rem 0.48rem 0rem 0rem;
  background: #fff;
  height: 6rem;
  overflow: auto;

  .title {
    text-align: center;
    margin: 0.36rem 0;
    font-size: 0.36rem;
    padding-bottom: 0.4rem;
    border-bottom: 0.01rem solid #333;
  }

  .pay-list {
    .item {
      @include flexbox();
      padding: 0 0.46rem;
      margin-top: 0.36rem;

      img {
        width: 0.54rem;
        height: 0.54rem;
        margin-right: 0.16rem;
      }

      .right {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 50%;
        border: 0.02rem solid #ffb267;
        position: relative;

        .active {
          width: 0.2rem;
          height: 0.2rem;
          background: #ffb267;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    // 选中状态
  }
}

// 会员权益
.active-card-right-box {
  max-width: $pcMaxWidth;
  margin-top: -1rem;
  border-radius: 0.36rem 0.36rem 0rem 0rem;
  background: #fff;
  padding: 0.28rem 0.25rem 0.18rem 0.25rem;
  text-align: center;
  position: relative;
  z-index: 2;
  // max-height: calc(100vh - 7.5rem );
  overflow: auto;

  img {
    height: 0.5rem;
    margin-bottom: 0.3rem;
  }
}

.right_box_sub {
  border-radius: 0.32rem;
  padding: 0.3rem 0.5rem;
  @include flexbox($fd: column, $ai: flex-start);
}

.active-card-right {
  border-radius: 0.12rem;
  width: 100%;
  @include flexbox($jc: flex-start, $fd: column, $ai: flex-start);
  flex: 1;
  padding-bottom: 1rem;

  .card_item {
    @include flexbox($jc: flex-start);
    margin-bottom: 0.36rem;

    .card_photo {
      width: 0.72rem;
      height: 0.72rem;
      margin-right: 0.24rem;
    }

    .card_info {
      text-align: left;

      span {
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.24rem;
        /* 80% */
      }

      p {
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.24rem;
        margin-top: 0.02rem;
        color: #999;
        /* 100% */
      }
    }
  }
}
.wrapBtn {
  position: fixed;
  padding: 0.2rem 0 0.1rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: #fff;
  text-align: center;
  .submit_btn {
    display: flex;
    width: 6.86rem;
    height: 0.88rem;
    margin: auto;
    padding: 0.22rem 2.2rem;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 0.44rem;
    background: $gradientBg;
    font-size: 0.36rem;
  }
  .foont {
    margin-top: 0.05rem;
    font-size: 0.24rem;
    color: rgba(153, 153, 153, 1);
  }
}

.tip-wrap {
  font-size: 0.24rem;
  margin-top: 0.5rem;
  padding: 0 0.4rem;
  opacity: 0.7;

  .title_txt {
    opacity: 1;
    font-weight: 600;
    font-size: 0.28rem;
    margin-bottom: 0.24rem;
  }
}
</style>
