<template>
  <div class="JGbtnList">
    <div class="JGContent">
      <div v-for="(item, index) in list" :key="index" @click="JumpFun(item.link)">
        <div>
          <EDecryptImg :imgURL="item.avatar"> </EDecryptImg>
        </div>
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
export default {
  components: {},
  props: ['list'],
  data() {
    return {}
  },
  methods: {
    JumpFun(url) {
      JumpTo(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.JGbtnList {
  width: 100%;
  height: 1.24rem;
  overflow-x: auto;
  margin-top: 0.18rem;
  > .JGContent {
    display: flex;
    align-items: center;
    > div {
      width: 1.05rem;
      height: 1.24rem;
      margin-right: 0.2rem;
      flex-shrink: 0;
      > div:first-child {
        width: 0.8rem;
        height: 0.8rem;
        margin: 0 auto;
        border-radius: 0.13rem;
        overflow: hidden;
      }
      > div:last-child {
        font-size: 0.24rem;
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin-top: 0.08rem;
        line-height: 0.36rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
