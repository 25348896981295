<template>
  <div ref="mine" class="md-mine">
    <div class="md-mine-main">
      <div class="main-top">
        <!-- 背景 -->
        <EBackTop :background="'transparent'" :fixed="false" class="mine-head" color="#333" title="">
          <slot slot="right" name="right">
            <router-link class="tag" to="/Mine/messages">
              <van-icon :dot="hasNewMsgTip" :name="require('@/assets/imgs/mine/msg.png')" />
            </router-link>
            <router-link class="tag" to="/Mine/setting">
              <van-icon :name="require('@/assets/imgs/mine/setting.png')"
            /></router-link>
          </slot>
        </EBackTop>
        <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="info.avatarUrl ? info.avatarUrl : ''"> </EDecryptImg>
        <!-- 用户信息 -->
        <div class="md-mine-info">
          <div class="md_mine_info_avatar">
            <EDecryptImg
              :imgCDN="imgCDN"
              class="md-mine-photo"
              :imgURL="info.avatarUrl ? info.avatarUrl : ''"
              @click.native="$router.push('/Mine/setting')"
            >
            </EDecryptImg>
            <div class="md-mine-account">
              <div class="name">
                <span>{{ info.nickName }}</span>
                <span class="movieTickets_num">LV. {{ info.userLevel }}</span>
              </div>
              <span class="desc">ID:{{ info.id }}</span>
              <div @click="$router.push('/Mine/setting/accoutCode')">
                <img src="@/assets/imgs/mine/sf_card.png" />
                <span>身份卡</span>
              </div>
              <!-- <span :class="info.loginType !== 0 ? 'isLogin_style' : 'login_btn'" @click.stop="goLogin">
                {{ `${info.loginType !== 0 ? '已登陆' : '登陆/注册'}` }}
              </span> -->
            </div>
          </div>
          <p class="user_introduction">
            {{ info.introduction ? info.introduction : '～有趣的介绍能让你的逼格提高N个档次！...' }}
          </p>
          <ul class="user_nums">
            <li>
              <div>
                <p>{{ info.movieTickets }}</p>
                <p>观影卷</p>
              </div>
              <span class="line"></span>
            </li>
            <li>
              <div>
                <p>{{ info.aiFreeRemoveClothCount }}</p>
                <p>AI脱衣券</p>
              </div>
              <span class="line"></span>
            </li>
            <li>
              <div>
                <p>{{ info.aiFreeChangeFaceCount }}</p>
                <p>AI换脸券</p>
              </div>
              <span class="line"></span>
            </li>
          </ul>

          <div class="sign-in" @click="$router.push('/mine/myShouyi?type=1')">
            <img src="@/assets/imgs/mine/sigin_icon.png" alt="" />
            签到
          </div>
        </div>
      </div>
      <!-- 下半部分 -->
      <div class="mine_bot_main">
        <div v-if="!isMember" class="md-mine-profile">
          <div class="vip_info" @click="$router.push('/Mine/vip')">
            <div class="left">
              <div class="title">
                <img src="@/assets/imgs/mine/vip_icon_hg.png" alt="" />
                VIP限时特惠 畅看全场
              </div>
              <div class="desc">开通 <span>VIP</span> 全场畅看 剩余可下载次数 0</div>
            </div>
            <div class="right">去开通</div>
          </div>
        </div>
        <div v-else class="md-mine-profile has-vip">
          <div class="vip_info" @click="$router.push('/Mine/vip')">
            <div class="left">
              <div class="title">
                您当前的会员类型是
                <img src="@/assets/imgs/mine/vip_icon_hg.png" alt="" />
              </div>
              <div class="desc"><span>到期时间</span> {{ info.vipExpireTime | timeYmd }}</div>
            </div>
            <div class="right">{{ info.cardName }}</div>
          </div>
        </div>
        <div class="mine-card">
          <div class="item" @click="$router.push('/Mine/vip')">
            <img src="@/assets/imgs/mine/vip_icon.png" alt="" />
            <p>会员中心</p>
            <div>享会员特权</div>
          </div>
          <div class="item" @click="$router.push('/Mine/myWallet')">
            <img src="@/assets/imgs/mine/gold_bg.png" alt="" />
            <p>金币充值</p>
            <div>当前余额 {{ info.balance | changeMoneyYuan }}</div>
          </div>

          <div class="item" @click="$router.push('/Mine/myShouyi')">
            <img src="@/assets/imgs/mine/tuiguang.png" alt="" />
            <p>推广福利</p>
            <div>帖子越多赚越多</div>
          </div>
        </div>
        <div class="md-mine-property-panel">
          <router-link to="/Mine/myPublish" class="md-mine-property-info">
            <img src="@/assets/imgs/mine/myPublic.png" alt="" />
            <p>我的发布</p>
          </router-link>
          <router-link to="/Mine/myCollect" class="md-mine-property-info">
            <img src="@/assets/imgs/mine/myCollect.png" alt="" />
            <p>我的收藏</p>
          </router-link>
          <router-link to="/Mine/myOrder" class="md-mine-property-info">
            <img src="@/assets/imgs/mine/myOrder.png" alt="" />
            <p>我的订单</p>
          </router-link>
          <router-link to="/Mine/myFocus" class="md-mine-property-info">
            <img src="@/assets/imgs/mine/myFocus.png" alt="" />
            <p>我的关注</p>
          </router-link>
        </div>
        <div class="md-mine-main-other">
          <!-- ai入口 -->
          <div class="ai_main" @click="$router.push('/ai/generate')">
            <img src="@/assets/imgs/mine/ai_main.png" alt="" />
          </div>
          <!-- 锁屏密码+桌面图标 -->
          <div class="setting_icon">
            <div class="lock_pwd">
              <span>锁屏密码</span>
              <van-switch
                v-model="checked"
                :size="'0.28rem'"
                inactive-color="#858585"
                active-color="#00BD7F"
                @change="changeLock"
              />
            </div>
            <div class="account" @click="goLogin">
              <span>账号密码<span>（自定义设置账号/密码）</span></span>
              <van-icon name="arrow" size="0.3rem" color="#858585" />
            </div>
          </div>
          <!-- 广告轮播 -->
          <AdAvatarList
            v-if="$store.state.config.config.mineAdsType === 2"
            :list="iconMineAds"
            :wrap="true"
            key-name="avatar"
          />

          <!-- 广告轮播 -->
          <div v-else-if="$store.state.config.config.mineAdsType !== 3 && mineAD.length" class="swiperPack">
            <EADSwiper
              :list="mineAD"
              :slidesPerView="1"
              keyName="cover"
              :EADconfig="EADconfig"
              :imgCDN="imgCDN"
              @clickImg="clickImg"
            >
            </EADSwiper>
          </div>
          <!-- 跳转列表 -->
          <div class="md-mroe-servic">
            <van-cell title="客服中心" is-link to="/Mine/setting/kfProblem">
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/kf.png')" class="md-mine-icon" />
              </template>
            </van-cell>
            <van-cell title="意见反馈" is-link to="/Mine/myBack">
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/fankui.png')" class="md-mine-icon" />
              </template>
            </van-cell>
            <van-cell title="浏览记录" is-link to="/Mine/myHistory">
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/myHistory.png')" class="md-mine-icon" />
              </template>
            </van-cell>
            <van-cell title="兑换中心" is-link to="/Mine/redemptionCode">
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/code.png')" class="md-mine-icon" />
              </template>
            </van-cell>
            <van-cell title="应用中心" is-link to="/Mine/cooperateApp">
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/app.png')" class="md-mine-icon" />
              </template>
            </van-cell>

            <!-- <van-cell title="账号凭证" is-link to="/Mine/setting/accoutCode">
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/account_id.png')" class="md-mine-icon" />
              </template>
            </van-cell> -->
            <van-cell title="官方社群" is-link to="/Mine/myGroup">
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/groupIcon.png')" class="md-mine-icon" />
              </template>
            </van-cell>
            <!-- <van-cell title="找回账号" is-link to="/Mine/setting/accout">
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/acc.png')" class="md-mine-icon" />
              </template>
            </van-cell> -->
            <!-- <van-cell title="常见问题" is-link to="/Mine/myQuestion">
              <template #icon>
                <van-icon :name="require('@/assets/imgs/mine/wenti.png')" class="md-mine-icon" />
              </template>
            </van-cell> -->
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showAccountImgBtn" class="code_pop" @close="closeAccountImg">
      <div class="code_pop_bg">
        <div class="account-img">
          <div ref="codeBg" class="code">
            <EGenerateImg
              ref="EGenerateImg"
              :link="qrCode"
              :name="`来自${info.nickName}的个人凭证.png`"
              :config="config"
            >
              <div class="code-bg"></div>
            </EGenerateImg>
          </div>
          <div class="account_info">
            <img src="@/assets/imgs/mine/logo_ewm.png" alt="" srcset="" />
            <span>男人本色 精品选择</span>
            <div class="desc van-ellipsis">永久域名 {{ ldyCdn }}</div>
          </div>
        </div>
        <div class="tip">如果账号丢失,请到 <span>设置-找回账号-账号凭证</span> 找回上传凭证或者扫描凭证</div>
        <div class="bottom-btn" @click="clickSaveImg">保存账号凭证到手机</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
// 处理参数类型
import { JumpTo } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
import { userQrcode, userInfo, userIsCert } from 'api/user'
import html2canvas from 'html2canvas'
import scrollMixin from '@/utils/scrollMixin'
export default {
  components: {
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      config: {
        qrSize: 200
      },
      web: '',
      checked: window.localStorage.getItem('hs_lockScreen') === 'true' ? true : false,
      hasNewMsgTip: false,
      app: '',
      qrCode: '',
      backup: '',
      showAccountImgBtn: false,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      }
    }
  },
  computed: {
    iconMineAds({ $store }) {
      return $store.getters['iconMineAds']
    },

    hasNewMsg({ $store }) {
      return $store.getters['hasNewMsg']
    },
    openGestureLock: {
      get() {
        return this.$store.getters['getOpenGestureLock']
      },
      set() {
        return this.$store.getters['getOpenGestureLock']
      }
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    mineAD({ $store }) {
      return $store.getters['mineAD']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    showAccountImg({ $store }) {
      return $store.state.user.showAccountImg
    },
    // 是否保存过个人凭证
    isSaveImg({ $store }) {
      return $store.state.user.isSaveImg
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  async mounted() {
    this.showAccountImgBtn = this.showAccountImg && !this.isSaveImg
    this.hasNewMsgTip = this.hasNewMsg

    await this.getUerQrcode()
    await this.getUserInfo()
  },
  methods: {
    // onCopy() {
    //     this.$toast('复制成功！！！')
    // },
    // onError() {
    //     this.$toast('复制失败！！！')
    // },

    changeLock(value) {
      this.$store.dispatch('setShowGestureLock', true)
      if (value) {
        this.$store.dispatch('setOpenGestureLock', value)
        this.$store.dispatch('changeLockStatus', false)
      } else {
        this.$store.dispatch('setOpenGestureLock', value)
        this.$store.dispatch('changeLockStatus', true)
      }
    },
    // // 登陆跳转
    goLogin() {
      // if (this.info.loginType === 0) {
      this.$router.push('/Mine/login')
      // }
    },
    async clickImg(item) {
      JumpTo(item.href)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    clickSaveImg() {
      userIsCert({
        isSave: true
      }).then(res => {
        if (res.code === 200) {
          this.$store.commit('isSaveImg', true)
          html2canvas(this.$refs.codeBg, {
            dpi: 300,
            scale: 2,
            useCORS: true // 解决文件跨域问题
          }).then(canvas => {
            canvas.toBlob(
              blob => {
                const url = URL.createObjectURL(blob)
                // 可以上传后端或者直接显示
                this.download(url)
              },
              'image/jpeg',
              0
            )
            this.$toast('保存成功')
          })
        } else {
          this.$toast('保存失败，请联系在线客服！')
        }
      })
      // this.$refs['EGenerateImg'].clickSaveImg()
    },

    onCopy() {
      this.$refs['EGenerateImg'].doCopy()
    },
    async getUserInfo() {
      const res = await userInfo()
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
      }
    },

    goRouter(path = '/') {
      this.$router.push(path)
    },

    async getUerQrcode() {
      const res = await userQrcode()
      if (res && res.code === 200) {
        this.web = res.data.web
        this.app = res.data.app
        this.qrCode = res.data.value
        this.backup = res.data.backup
      }
    },

    download(href) {
      const eleLink = document.createElement('a')
      eleLink.href = href
      eleLink.setAttribute('download', `来自${this.info.nickName}的个人凭证`)
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
      this.$store.commit('isSaveImg', true)
      this.closeAccountImg()
    },
    closeAccountImg() {
      this.showAccountImgBtn = false
      this.$store.commit('SET_ACCOUNT_IMG', false)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep() {
  .BackTop {
    box-shadow: none;
  }
}

.md-mine-info {
  z-index: 1;
  position: relative;

  .md_mine_info_avatar {
    @include flexbox($jc: flex-start);
    .md-mine-account {
      .name {
        display: flex;
        align-items: center;
        span:last-child {
          border-radius: 0.2rem;
          padding: 0.02rem 0.08rem;
          border: 0.02rem solid #000;
          background: #ffd300;
          color: #000;
          font-family: 'PingFang SC';
          font-size: 0.2rem;
          font-style: normal;
        }
      }
      .desc {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.24rem;
      }
      div:last-child {
        display: flex;
        width: 1.3rem;
        align-items: center;
        padding: 0 0.05rem;
        border-radius: 0.1rem;
        border: 0.02rem solid #9c9694;
        font-size: 0.24rem;
        color: #fff;
        background: #392c2a;
        margin-top: 0.03rem;
        img {
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.05rem;
        }
      }
    }
  }

  .user_id {
    margin: 0.24rem 0;

    .desc {
      color: rgba($color: #fff, $alpha: 0.9);
      font-family: 'PingFang SC';
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 500;
      line-height: 0.36rem;

      /* 150% */
      /* 150% */
      span {
        color: #ffd300;
        opacity: 0.6;
      }
    }

    .line {
      width: 0.02rem;
      display: inline-block;
      height: 0.2rem;
      background: rgba($color: #fff, $alpha: 0.9);
      margin: 0 0.24rem;
    }
  }

  .user_introduction {
    height: 0.7rem;
    overflow: hidden;
    color: #999;
    font-size: 0.24rem;
    margin-top: 0.3rem;
  }

  .user_nums {
    @include flexbox($jc: center);
    margin-top: 0.1rem;

    li {
      @include flexbox();
      text-align: center;

      p {
        color: rgba(255, 255, 255, 0.8);
        &:first-child {
          font-size: 0.32rem;
        }

        &:last-child {
          margin-top: 0.12rem;
          color: rgba($color: #fff, $alpha: 0.8);
        }
      }

      .line {
        display: block;
        content: '';
        width: 0.02rem;
        height: 0.4rem;
        opacity: 0.2;
        background: #fff;
        margin: 0 0.88rem;
      }

      &:last-child {
        .line {
          display: none;
        }
      }
    }
    li:nth-child(2),
    li:nth-child(3) {
      p:last-child {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.main-top {
  border-radius: 0.14rem;
  height: 6.4rem;
  position: relative;
  padding: 0.17rem 0.32rem 0 0.32rem;

  .mine_top_bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &::after {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      background: rgba(42, 22, 20, 0.6);
      backdrop-filter: blur(6px);
      height: 6.4rem;
      width: 100%;
    }
  }
}

.md-mine-property-panel {
  > a {
    flex: 1;
  }
}

.mine-card {
  margin: 0 0 0.16rem;
  @include flexbox($jc: center);

  .item {
    flex: 1;
    font-size: 0.24rem;
    @include flexbox($jc: center, $fd: column);
    position: relative;
    background: url('../../assets/imgs/mine/item_1.png') no-repeat;
    height: 2.3rem;
    background-size: 100% 100%;

    img {
      width: 1rem;
      height: 1rem;
      margin-top: 0.26rem;
    }

    div {
      font-size: 0.22rem;
      color: #cd98a1;
    }

    &:nth-child(2) {
      margin-right: 0.16rem;

      p {
        margin: 0.1rem 0 0.06rem 0;
      }

      img {
        margin-top: 0.28rem;
      }
    }

    &:first-child {
      margin-right: 0.16rem;

      p {
        margin: 0.12rem 0 0.06rem 0;
      }
    }

    &:last-child {
      p {
        margin: 0.12rem 0 0.06rem 0;
      }
    }
  }
}

.md-mine {
  min-height: 100vh;
  position: relative;

  .mine-head {
    :deep() {
      .van-nav-bar {
        border: none;

        .van-nav-bar__content {
          .van-nav-bar__left {
            display: none;
          }
        }

        .van-nav-bar__right {
          padding: 0;

          img {
            width: 0.48rem;
            height: 0.48rem;
          }
        }
      }
    }
  }

  &-photo {
    width: 1.3rem !important;
    height: 1.3rem !important;
    border-radius: 50%;
    overflow: hidden;
  }

  &-account {
    margin-left: 0.4rem;
    height: 100%;
    line-height: 1.5;

    .name {
      font-size: 0.32rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      @include flexbox($jc: flex-start);
      color: #fff;

      span:first-child {
        margin-right: 0.12rem;
      }
    }
  }

  .mine_bot_main {
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    background: var(--f5f5f5, #f5f5f5);
    padding: 0 0.34rem;
    position: relative;
    z-index: 3;
    top: -1.2rem;
    padding-bottom: 0.5rem;
  }

  &-profile {
    position: relative;
    width: 6.86rem;
    height: 1.28rem;
    border-radius: 0.16rem;
    background: linear-gradient(96deg, #fabd75 8.78%, #ffd392 42.28%, #ffbe5d 90.47%);
    margin: 0rem auto;
    top: -0.55rem;

    .vip_info {
      position: absolute;
      height: 100%;
      width: 100%;
      @include flexbox();
      padding: 0 0.31rem;
      left: 0;
      top: 0;
      z-index: 1;
      font-size: 0.28rem;

      .left {
        .title {
          color: #945000;
          @include flexbox($jc: flex-start);

          img {
            width: 0.36664rem;
            height: 0.31666rem;
            margin-right: 0.12rem;
          }
        }

        .desc {
          color: rgba(148, 80, 0, 0.6);

          font-size: 0.24rem;
          font-weight: 400;
        }

        img {
          width: 0.3rem;
          height: 0.26rem;
          flex-shrink: 0;
        }
      }

      .right {
        padding: 0.08rem 0.32rem;
        border-radius: 1.28rem;
        background: linear-gradient(102deg, #df7800 1.91%, #c84710 118.24%);
        box-shadow: 0px 3px 3px 0px rgba(136, 30, 39, 0.2);
        color: #fff;
      }
    }
  }

  .has-vip {
    background: url('../../assets/imgs/mine/has_vip_bg.png') no-repeat;
    background-size: 100% 100%;

    .left {
      .title {
        margin-bottom: 0.04rem;

        img {
          margin-left: 0.12rem;
        }
      }

      .desc {
        span {
          padding: 0.04rem 0.12rem;
          border-radius: 0.32rem;
          background: rgba(148, 80, 0, 0.5);
          color: #fff;
        }
      }
    }
  }

  &-property-panel {
    margin: 0.2rem 0;
    border-radius: 0.2rem;
    background: #fff;
    padding: 0.24rem;
    @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
  }

  &-property-info {
    @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
    margin-right: 0.52rem;

    &:last-child {
      margin-right: 0;
    }

    img {
      margin-bottom: 0.25rem;
    }

    &:nth-child(1) {
      img {
        width: 0.56rem;
        height: 0.56rem;
      }
    }

    &:nth-child(2) {
      img {
        width: 0.64rem;
        height: 0.56rem;
      }
    }

    &:nth-child(3) {
      img {
        width: 0.64rem;
        height: 0.56rem;
      }
    }

    &:nth-child(4) {
      img {
        width: 0.56rem;
        height: 0.56rem;
      }
    }

    p {
      font-size: 0.22rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      margin: 0 0;
      padding: 0 0;
    }
  }

  .swiperPack {
    width: 100%;
    border-radius: 0.1rem;
    overflow: hidden;
    height: 1.6rem;
  }

  .setting_icon {
    border-radius: 0.2rem;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 0.32rem;
    margin: 0.36rem 0;

    .lock_pwd {
      @include flexbox();
    }
    .account {
      display: flex;
      align-content: center;
      margin-top: 0.3rem;
      justify-content: space-between;
      span {
        span {
          color: #999;
          font-size: 0.2rem;
        }
      }
    }
  }

  .md-mroe-servic {
    border-radius: 0.14rem;
    background-color: #fff;
    margin-top: 0.3rem;

    .van-cell {
      background-color: transparent;
      font-size: 0.26rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      justify-content: space-between;
      border-bottom: 0.01rem solid #f0f0f0;

      &:last-child {
        border-bottom: none;
      }
    }

    :deep() {
      .van-cell::after {
        display: none !important;
      }

      .van-cell__value {
        flex: none;
        color: #fff;
        border-radius: 0.08rem;
        border: 1px solid #e8619d;
        background: rgba(134, 36, 36, 0.16);
        padding: 0 0.12rem;
      }
    }

    .md-mine-icon {
      font-size: 0.28rem;
      padding: 0.05rem 0;

      img {
        width: 0.36rem;
        height: 0.36rem;
        object-fit: contain;
        margin-right: 0.12rem;
      }
    }
  }

  .tag {
    font-size: 0.38rem;
    margin: 0 0.1rem;
    position: relative;
    top: 0.05rem;
  }
}

.code_pop {
  background-color: transparent;
}

.code_pop_bg {
  background-image: url('../../assets/imgs/mine/mine_code_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 6rem;
  padding-bottom: 0.64rem;
  text-align: center;
  border-radius: 0.32rem;

  .title {
    width: 2rem;
  }

  .tip {
    margin: 0.32rem 0 0.2rem 0;
    font-size: 0.24rem;
    text-align: center;

    span {
      color: $btnBg;
    }
  }
}

.account-img {
  padding: 7.8rem 0.36rem 0 0.36rem;
  @include flexbox();
  flex-shrink: 0;
  border-radius: 0.2rem;

  .code {
    // border-radius: 0.16rem;
    // background: #fff;
    // width: 1.8rem;
    // height: 1.8rem;
    // flex-shrink: 0;
    // margin-bottom: 0.52rem;
    // @include flexbox($jc: center);
    // margin-right: 0.15rem;
    // padding: 0.14rem 0.2rem;

    :deep() {
      .qrBg {
        width: 1.8rem !important;
        height: 1.8rem !important;
        left: 50%;
        top: 1rem;
        transform: translate(-50%, -50%);
      }

      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .code-bg {
      padding: 0.2rem;
      width: 2rem;
      height: 2rem;
      margin: 0 auto;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
}

.account_info {
  @include flexbox($fd: column);
  img {
    width: 2.12rem;
    height: 0.72rem;
  }
  span {
    margin: 0.15rem 0 0.03rem 0;
    font-size: 0.32rem;
    color: #333;
  }

  .desc {
    word-break: break-all;
    color: $btnBg;
    max-width: 3.2rem;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.bottom-btn {
  border-radius: 0.12rem;
  background: $gradientBg;
  padding: 0.16rem 0.48rem;
  color: #fff;
  text-align: center;
  display: inline-block;
}

// 新增登陆注册
.login_btn {
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.02rem 0.12rem;
  border-radius: 0.08rem;
  background: $gradientBg;
  color: #fff;
}

.isLogin_style {
  border-radius: 0.04rem;
  background: rgba(221, 0, 27, 0.2);
  margin-left: 0.16rem;
  color: #fff;
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.md-mine-sj {
  border-radius: 0.08rem;
  border: 1px solid #777;
  background: rgba(0, 0, 0, 0.6);
  width: 6.22rem;
  padding: 0.4rem 0.32rem;
  margin: 0 auto;
  text-align: center;

  p:first-child {
    font-size: 0.5rem;
  }

  p:last-child {
    border-radius: 0.18rem;
    background: rgba($color: #e8619d, $alpha: 0.7);
    display: inline-flex;
    padding: 0.01rem 0.1rem;
    font-size: 0.18rem;
  }
}

.vip_active {
  .title {
    background: linear-gradient(265deg, #ffbd8d 4.92%, #ffd2b5 95.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .right_active {
    text-align: right;

    p:last-child {
      border-radius: 0.06rem;
      background: rgba(0, 0, 0, 0.5);
      padding: 0.02rem 0.12rem;
    }
  }
}

.ai_main {
  position: relative;

  img {
    height: 1.6rem;
    width: 100%;

    &:nth-of-type(2) {
      width: 0.71rem;
      height: 0.41rem;
      position: absolute;
      left: 2.38rem;
      bottom: 0.12rem;
    }
  }
}

.sign-in {
  position: absolute;
  right: -0.3rem;
  top: 0.3rem;
  border-radius: 0.32rem 0rem 0rem 0.32rem;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.12rem 0.14rem 0.12rem 0.2rem;
  @include flexbox();
  color: #fff;

  img {
    width: 0.30334rem;
    height: 0.28rem;
    margin-right: 0.02rem;
  }
}
</style>
