import { render, staticRenderFns } from "./postDetailWithOpr.vue?vue&type=template&id=f4dcc524&scoped=true&"
import script from "./postDetailWithOpr.vue?vue&type=script&lang=js&"
export * from "./postDetailWithOpr.vue?vue&type=script&lang=js&"
import style0 from "./postDetailWithOpr.vue?vue&type=style&index=0&id=f4dcc524&prod&lang=scss&scoped=true&"
import style1 from "./postDetailWithOpr.vue?vue&type=style&index=1&id=f4dcc524&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4dcc524",
  null
  
)

export default component.exports