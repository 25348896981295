<template>
  <div class="footer">
    <div class="foot-l">
      <div class="item">
        <img src="@/assets/imgs/picon1.svg" alt="" />
        {{ data.base.watches | numberFilter }}
      </div>
      <div class="item">
        <img src="@/assets/imgs/picon3.svg" alt="" />
        {{ data.base.comments }}
      </div>
      <div class="item">
        <img src="@/assets/imgs/picon2.svg" alt="" />
        {{ data.base.likes | numberFilter }}
        <!-- 点赞 -->
      </div>
    </div>
    <div
      v-if="data.base.postSection"
      class="foot-r"
      @click.stop="$router.push(`/community/tagDetail/${data.base.postSectionId}?title=${data.base.postSection}`)"
    >
      #{{ data.base.postSection }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostItemOpr',
  components: {},
  filters: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
// 底部信息
.footer {
  font-size: 0.24rem;
  display: flex;
  justify-content: space-between;
  // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  .foot-l {
    display: flex;
    align-items: center;
    color: #999999;
    .item {
      display: flex;
      align-items: center;
      margin-right: 0.64rem;
    }
    img {
      width: 0.28rem;
      height: 0.2rem;
      margin-right: 0.1rem;
    }
  }
  .foot-r {
    flex: none;
    display: flex;
    padding: 0.02rem 0.08rem;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #246bfd;
    font-size: 0.24rem;
  }

  .comment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.2rem;
    div {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 0.1rem;
      height: 0.3rem;
    }
  }
  &-left {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: nowrap);
    color: #939496;
    width: 100%;
    margin-top: 0.2rem;
    div {
      display: flex;
      align-items: center;
      width: 33.33%;
      justify-content: center;
      img {
        margin-right: 0.1rem;
        height: 0.3rem;
      }
    }
    &.wantTo {
      @include flexbox($jc: right, $ai: center, $fd: row, $fw: nowrap);
    }
  }
  &-right {
    color: #000000;
    font-size: 0.24rem;
    // background: linear-gradient(to right, #fd9c3a, #fc342d);
    background: $btnBg;
    padding: 0.03rem 0.27rem;
    border-radius: 0.34rem;

    span {
      font-size: 0.24rem;
    }
    img {
      width: 0.22rem;
      height: 0.22rem;
      margin: 0 0.03rem;
    }
  }
}
</style>
