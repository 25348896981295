<template>
  <div class="discover">
    <search-box class="search-box" />
    <EADSwiper
      :list="discoverAD"
      class="swiper"
      key-name="cover"
      :EADconfig="EADconfig"
      :img-c-d-n="imgCDN"
      @clickImg="clickImg"
    >
    </EADSwiper>
    <ul>
      <li v-for="(item, index) in topTabbarList" :key="index" @click="$router.push(item.path)">
        <img :src="item.src" alt="" />
      </li>
    </ul>
    <div class="rank">
      <!-- <div class="top">
        <span>热门排行榜</span>
        <van-icon name="arrow" size="0.32rem" />
      </div> -->
      <MyTitle
        v-if="find_topic_by_showType(12)"
        :text="find_topic_by_showType(12)['topic']['name']"
        @onclick="toPage({ path: '/discover/ranking' })"
      ></MyTitle>
      <div class="list-container">
        <ul @touchmove.stop>
          <RankItem
            v-for="(item, index) in showRankList"
            :key="index"
            class="hot_circle"
            :class="{ linear2: index % 2 != 0 }"
            :list="item"
            :index="index"
          />
        </ul>
      </div>
      <div class="view-all" @click="toPage({ path: '/discover/ranking' })">查看完整排行榜</div>
    </div>
    <div v-if="find_topic_by_showType(13)" class="photo-sta">
      <MyTitle :text="find_topic_by_showType(13)['topic']['name']" @onclick="toPage({ path: '/discover/photo' })" />
      <!-- <PhotoList :list="find_topic_by_showType(13)['photoList']" /> -->
      <EWaterFallH :datasList="find_topic_by_showType(13)['photoList']">
        <template #top="{ handleLoad, data }">
          <ImgshowType4 :data="data" class="topItem" @loadedImg="handleLoad"></ImgshowType4>
        </template>
        <template #btom="{ handleLoad, data }">
          <ImgshowType4 :data="data" class="btomItem" @loadedImg="handleLoad"></ImgshowType4>
        </template>
      </EWaterFallH>
    </div>
    <div class="hot-play list-container">
      <EDecryptImg :imgCDN="imgCDN" class="list-container-bg" :imgURL="showSomeData.cover" />
      <MyTitle
        v-if="find_topic_by_showType(14)"
        :text="find_topic_by_showType(14)['topic']['name']"
        @onclick="toPage({ path: '/discover/playlist' })"
      />
      <ul @touchmove.stop>
        <li v-for="(item, index) in playlist" :key="index" class="hot-play-item" @click="clickPlaylist(index, item)">
          <!-- <img src="@/assets/imgs/temp/bodan.png" alt="" /> -->
          <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.cover || item.avatar" />

          <!-- <div class="top">
            <img src="@/assets/imgs/discover/video_tag.png" alt="" />
          </div> -->
          <div class="bottom">
            <div>
              <img src="@/assets/imgs/discover/layer.png" alt="" />
              <span>{{ item.total }}</span>
            </div>
            <div>
              <img src="@/assets/imgs/discover/video.png" alt="" />
              视频
            </div>
          </div>
          <div v-if="active_playlist_idx !== index" class="mask"></div>
        </li>
      </ul>
      <div v-if="playlist" class="center-intro">
        <div>{{ showSomeData.title }}</div>
        <div>
          <span>@{{ showSomeData.userName }}</span>
          <span>{{ showSomeData.total }}个视频</span>
        </div>
      </div>
      <ul ref="playlist-sub-list" @touchmove.stop>
        <li v-for="item in showPlayList" :key="item.id" class="hot-play-video" @click="clickPlaylistItem(item)">
          <div>
            <!-- <img src="@/assets/imgs/temp/bodan.png" alt="" /> -->
            <EDecryptImg :imgCDN="imgCDN" class="playlist-img" :imgURL="item.coverImg || item.avatar" />

            <div class="poa-bottom">
              <div>
                <img src="@/assets/imgs/discover/brand-youtube.png" alt="" />
                <span>{{ item.watchTimes | numberFilter }}</span>
              </div>
              <span>{{ item.playTime | secondToDate }}</span>
            </div>
          </div>
          <div>{{ item.title }}</div>
        </li>
      </ul>
    </div>
    <HotList
      v-if="find_topic_by_showType(15)"
      :list="find_topic_by_showType(15)['actorList']"
      :title="find_topic_by_showType(15)['topic']['name']"
      :sort="0"
    />
    <HotList
      v-if="find_topic_by_showType(16)"
      :list="find_topic_by_showType(16)['modelList']"
      :title="find_topic_by_showType(16)['topic']['name']"
      :sort="1"
    />
    <HotList
      v-if="find_topic_by_showType(17)"
      :list="find_topic_by_showType(17)['anchorList']"
      :title="find_topic_by_showType(17)['topic']['name']"
      :sort="2"
    />
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
import { findpage_topic } from '@/api/discover'
import { advertiseClick } from 'api/home'
import scrollMixin from '@/utils/scrollMixin'

export default {
  components: {
    RankItem: () => import('./components/rankItem.vue'),
    MyTitle: () => import('./components/title.vue'),
    HotList: () => import('./components/hotList.vue'),
    ImgshowType4: () => import('components/Home/showType/pictrueListType/imgshowType4.vue'),
    SearchBox: () => import('./components/topSearch.vue')

    // PhotoList: () => import('./components/photoList.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      activeIndex: 0,
      topTabbarList: [
        {
          src: require('@/assets/imgs/discover/entrance1.png'),
          path: '/discover/ranking'
        },
        {
          src: require('@/assets/imgs/discover/entrance2.png'),
          path: '/discover/photo'
        },
        // {
        //   src: require('@/assets/imgs/discover/entrance3.png'),
        //   path: '/discover/theme'
        // },
        {
          src: require('@/assets/imgs/discover/entrance4.png'),
          path: '/discover/playlist'
        },
        {
          src: require('@/assets/imgs/discover/entrance5.png'),
          path: '/discover/girl'
        }
      ],
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      pageNum: 1,
      pageSize: 10,
      topicList: [],
      showRankList: [],
      playlist: [],
      active_playlist_idx: 0,
      imgList: [
        { imgUrl: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
        { imgUrl: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg' },
        { imgUrl: 'https://picsum.photos/300/300/?image=41' },
        { imgUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
        { imgUrl: 'https://placekitten.com/1000/300' },
        { imgUrl: 'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png' },
        { imgUrl: 'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg' },
        { imgUrl: 'https://placekitten.com/g/300/450' },
        { imgUrl: 'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg' },
        { imgUrl: 'https://placekitten.com/380/2003/323' },
        { imgUrl: 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg' },
        { imgUrl: 'https://picsum.photos/400/400/?image=20' },
        { imgUrl: 'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg' },
        { imgUrl: 'https://picsum.photos/900/250/?image=3' },
        { imgUrl: 'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg' },
        { imgUrl: 'https://picsum.photos/1024/480/?image=10' },
        { imgUrl: 'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg' },
        { imgUrl: 'https://picsum.photos/1024/480/?image=12' },
        { imgUrl: 'https://picsum.photos/1024/480/?image=22' }
      ]
    }
  },
  computed: {
    discoverAD({ $store }) {
      return $store.getters['discoverAD']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    showPlayList() {
      if (!this.playlist.length) return []
      return this.playlist[this.active_playlist_idx].mediaList
    },
    showSomeData() {
      if (!this.playlist.length) return []
      return this.playlist[this.active_playlist_idx]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    clickPlaylistItem(item) {
      this.$router.push({
        path: `/play/longVideo/${item.id}`
      })
    },
    clickPlaylist(idx, item) {
      if (this.active_playlist_idx == idx) {
        this.$router.push({
          path: `/discover/detail`,
          query: {
            id: item.id,
            type: item.type
          }
        })
        return
      }
      this.active_playlist_idx = idx
      this.$refs['playlist-sub-list'].scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    },
    clickImg(item) {
      advertiseClick({
        id: item.id
      }).then(res => {
        if (res.code === 200) {
          console.log(res)
        } else {
          return this.$toast('统计出错！')
        }
      })
      if (item.href) {
        JumpTo(item.href)
      } else {
        JumpTo(item.download_url)
      }
    },
    toPage(item) {
      this.$router.push(item)
    },
    async getList() {
      try {
        const res = await findpage_topic({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          categoryId: this.findCategory[0].id
        })
        if (res.code === 200) {
          this.topicList = res.data.list
          const { mediaRank, photoRank, postRank, playlistRank, actorRank } = this.find_topic_by_showType(12)

          this.showRankList = [mediaRank, photoRank, postRank, playlistRank, actorRank]

          this.playlist = this.find_topic_by_showType(14).playList
          console.log('this.playlist', this.playlist)
        }
      } catch (error) {
        this.$toast('请求错误，请稍后再试！')
      }
    },
    find_topic_by_showType(showtype) {
      // const map = {
      //   12: '榜单三列横滑',
      //   13: '套图横版瀑布流横滑',
      //   14: '热门播单横滑',
      //   15: '女优三列横滑',
      //   16: '模特三列横滑',
      //   17: '主播三列横滑'
      // }
      return this.topicList.find(v => {
        return v.topic.showType == showtype
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.discover {
  padding: 1rem 0.32rem 2.04rem;
  .search-box {
    position: fixed;
    top: 0;
    left: 50%;
    right: 0;
    z-index: 99;
    width: 100%;
    transform: translate(-50%, 0);
    max-width: $pcMaxWidth;
    padding-top: 0.2rem;
  }
  > .swiper {
    height: 2.86rem;
    border-radius: 0.16rem;
  }
  > ul {
    display: flex;
    margin: 0.36rem 0;
    padding: 0 0.43rem;
    img {
      width: 0.96rem;
    }
    > li {
      margin-right: 0.72rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  > .rank {
    .view-all {
      color: $grey6;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      margin: 0.24rem auto 0.24rem;
      text-align: center;
    }
  }
  .list-container {
    position: relative;
    @media (pointer: coarse) {
      ul::-webkit-scrollbar {
        display: initial;
      }
    }
    .list-container-bg {
      position: absolute;
      top: 0;
      left: -0.32rem;
      width: 7.5rem;
      height: 6.8rem;
      &::after {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
        filter: blur(4px);
      }
    }
    ul {
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      border-radius: 0.08rem;
      /* IE 10+ */
      width: 100%;
      overflow-x: scroll;
      @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: nowrap, $flex: 1);
      flex-shrink: 1;
      .hot_circle {
        min-width: 6.4rem;
        background: $linear1;
        border-radius: 0.08rem;
        position: relative;
        margin-right: 0.16rem;
        padding: 0.36rem 0.24rem;
        &.linear2 {
          background: $linear2;
        }
      }
    }
  }
  > .photo-sta {
    border-top: 1px solid $greyf0;
    padding-top: 0.36rem;
    margin-top: 0.36rem;
    margin-bottom: 0.8rem;
    ::v-deep {
      .top {
        margin-bottom: 0.2rem;
      }
    }
    .topItem,
    .btomItem {
      margin-right: 0.02rem;
    }
  }
  > .hot-play {
    padding-top: 0.2rem;
    // > ul {
    //   display: flex;
    // }
    .hot-play-item {
      min-width: 2.22rem;
      height: 2.22rem;
      border-radius: 0.06rem;
      margin-right: 0.1rem;
      position: relative;
      > .top {
        position: absolute;
        right: 0.08rem;
        top: 0.08rem;
        width: 0.28rem;
      }
      > .bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0.6rem;
        background: rgba($color: #000000, $alpha: 0.5);
        width: 100%;
        color: $white;
        @include flexbox;
        padding: 0 0.12rem;
        font-size: 0.24rem;

        img {
          width: 0.24rem;
          margin-right: 0.08rem;
        }
        > div {
          @include flex-align-center;
        }
      }
      .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(2px);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
      }
      img {
        width: 100%;
        margin-right: 0.12rem;
      }
    }
    .hot-play-video {
      margin-right: 0.14rem;

      > div {
        &:first-child {
          position: relative;
          .playlist-img {
            min-width: 3.04rem;
            height: 1.72rem;
          }
        }
        &:last-child {
          color: $grey;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 500;
          margin-top: 0.12rem;
          @include textoverflow;
        }
      }
      .poa-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        @include flexbox;
        background: rgba($color: #000000, $alpha: 0.5);
        color: $white;
        width: 100%;
        font-size: 0.2rem;
        font-style: normal;
        font-weight: 400;
        padding: 0 0.12rem;
        img {
          min-width: 0.28rem !important;
          height: 0.28rem !important;
          margin-right: 0.04rem;
          vertical-align: text-bottom;
        }
      }
    }
    > .center-intro {
      z-index: 3;
      position: relative;
      > div {
        &:nth-child(1) {
          color: #000;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 500;
          margin-top: 0.14rem;
        }
        &:nth-child(2) {
          color: #666;
          font-family: 'PingFang SC';
          font-size: 0.22rem;
          font-style: normal;
          font-weight: 400;
          margin: 0.04rem 0 0.24rem;
          > span:first-child {
            color: #f38c26;
            margin-right: 0.24rem;
          }
          > span:last-child {
            color: #fff;
            font-size: 0.2rem;
            background: #727171;
            border-radius: 0.2rem;
            padding: 0.02rem 0.08rem;
          }
        }
      }
    }
  }
}
</style>
