<template>
  <div class="sort-play home-page-video-style" :class="{ 'home-page-video-style': true }">
    <EBackTop :background="'transparent'" :open-fallback="true" :color="'#fff'" :placeholder="false">
      <template #right>
        <img v-if="showOpr" src="@/assets/imgs/short-video/search.png" alt="" @click="$router.push('/search')" />
      </template>
    </EBackTop>
    <div :id="'short-play'" class="play-box"></div>
    <div class="bg-img">
      <!--      <img v-if="!canPlay" src="@/assets/imgs/short-video/short-video-bg.png" alt="" />-->
    </div>
    <!--    <div v-if="!isShowVip" class="freeTimes">-->
    <!--      免费次数剩余：<span>{{ watchCount }}</span>-->
    <!--    </div>-->
    <!-- 视频轮播 -->
    <div id="ShortVideoPage" ref="ShortVideoPage" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in virtualData.slides"
          :key="index"
          class="swiper-slide"
          :style="{
            top: `${virtualData.offset}px`,
            backgroundColor: slideChangeTransition ? '#000' : 'transparent'
          }"
        >
          <div class="slide-mark" @click="handleClickSlideMark">
            <transition name="van-fade">
              <div v-if="!showVolume" class="volume-tip" @click.stop="clickCloseVolume">
                <van-icon name="volume-o" />
                <span>解除静音</span>
              </div>
            </transition>
            <div class="right-icon">
              <template v-if="showOpr">
                <div class="right-icon-likes">
                  <van-icon
                    :style="{ fontSize: '30px', color: itemVideoInfo.isCollect ? 'red' : '#fff' }"
                    name="like"
                    @click.stop="addLike(itemVideoInfo, 1)"
                  />
                  <span>{{ itemVideoInfo.collects | numberFilter }}</span>
                </div>
                <div class="right-icon-comment" @click="showCommentPanel = true">
                  <van-icon color="#fff" size="30" :name="require('@/assets/imgs/short-video/comment.png')" />
                  <p>{{ itemVideoInfo.comments }}</p>
                </div>
                <div class="right-icon-share" @click.stop="showCommunityShare = true">
                  <img src="@/assets/imgs/play/share1.png" alt="" />
                  <span>转发</span>
                </div>
              </template>

              <div class="right-icon-fold">
                <img v-if="showOpr" src="@/assets/imgs/short-video/show_eye.png" alt="" @click="showOpr = false" />
                <img v-else src="@/assets/imgs/short-video/hidden_eye.png" alt="" @click="showOpr = true" />
              </div>
            </div>
            <div v-if="showOpr" class="video-info">
              <div>
                <div v-if="itemVideoInfo.price && !itemVideoInfo.isBuy" class="pay-coin" @click="skipPreview">
                  {{ videoPrice }}金币解锁
                </div>
                <div
                  v-else-if="
                    !itemVideoInfo.price && itemVideoInfo.payType === 1 && !itemVideoInfo.isBuy && !isVipMember
                  "
                  class="buy-vip"
                  @click="skipPreview"
                >
                  开通会员 畅享完整内容
                </div>
              </div>
              <!-- 标签 -->
              <div class="tags-list">
                <span v-for="sitem in itemVideoInfo.tagList" :key="sitem" @click.stop="toTagList(sitem)"
                  >#{{ sitem.name }}</span
                >
                <!-- 是否为你的关注 -->
                <!-- <div class="isFollow" v-if="itemVideoInfo.publisher.isFollow">您的关注</div> -->
              </div>
              <!-- 付费按钮 -->
              <!-- <div class="publisher-info">
                <p>
                  {{
                    itemVideoInfo.publisher && itemVideoInfo.publisher.name ? `@${itemVideoInfo.publisher.name}` : ''
                  }}
                </p>
                <div class="pay-btn" v-if="!playable || code !== 200" @click.stop="showBuyPorp">
                  <div v-if="code === 6033" class="price">
                    {{ itemVideoInfo.price | changeGold }}
                    <img src="@/assets/imgs/mine/gold.png" alt="" />
                  </div>
                  <span> 观看完整视频 {{ item.playTime | secondToDate }}</span>
                </div>
              </div> -->
              <div class="video-title">
                {{ itemVideoInfo.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论 -->
    <van-popup
      v-model="showCommentPanel"
      round
      position="bottom"
      :style="{ height: '9rem' }"
      @click-overlay="closePanel"
    >
      <Comment
        v-if="showCommentPanel"
        class="comment-box"
        :publisher="itemVideoInfo.publisher"
        :object-id="itemVideoInfo.id"
        :objectype="1"
        :show-opr="true"
      />
    </van-popup>
    <NoticeVipPop v-model="showVipPop" @showVip="goToVip" />
    <NoticeCoinPop v-model="showCoinPop" @showCoin="goToCoin" />
    <ConfirmCoinPop
      v-model="showConfirmCoinPop"
      :movieTickets="user.movieTickets"
      :movieDiscount="itemVideoInfo.movieDiscount"
      :price="itemVideoInfo.price"
      @confirm="payBtnFn"
    />
    <SharePopup
      :sharePopShow="showCommunityShare"
      @close="
        e => {
          showCommunityShare = e
        }
      "
    />
    <!-- 会员购买弹窗-->
    <BuyVipPop ref="BuyVipPop" />
    <!--  金币购买弹窗-->
    <BuyGoldPop ref="BuyGoldPop" :videoInfo="itemVideoInfo" />
  </div>
</template>

<script>
import { video_play, video_pay } from 'api/play'
import { collect, homeRecommendList } from 'api/home'
import { throttle } from '@/utils/utils_tools'
import { mediaTopic } from 'api/acg'

const HlsJsPlayer = require('xgplayer-hls.js')
export default {
  components: {
    Comment: () => import('@/components/comment/index.vue'),
    NoticeVipPop: () => import('@/components/Popup/noticeVipPop.vue'),
    NoticeCoinPop: () => import('@/components/Popup/noticeCoinPop.vue'),
    ConfirmCoinPop: () => import('@/components/Popup/confirmCoinPop.vue'),
    SharePopup: () => import('@/components/SharePopup.vue'),
    BuyVipPop: () => import('@/components/Popup/buyVipPop.vue'),
    BuyGoldPop: () => import('@/components/Popup/buyGoldPop.vue')
  },
  layout: 'noComponent',
  props: {
    homeSvParams: {
      type: Object,
      default() {
        return {
          nowPosit: ''
        }
      }
    }
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      list: [],
      canPlay: false,
      code: 0, // 视频code
      playable: false, // 视频能否播放
      activeIndex: 0, // 接收到的默认播放数据索引
      swiper: null,
      showVolume: false, // 控制静音按钮
      slideChangeTransition: false, // 轮播变化时
      throttle: null, // 节流函数
      buyPorp: false, // 购买弹窗
      showPayBtn: false, //购买确认弹窗控制
      itemVideoInfo: {
        publisher: {}
      }, //当前播放视频信息
      virtualData: {
        slides: []
      },
      controlsDOM: '', // 控制台
      player: '', // 播放器实例
      watchCount: 0, // 免费观看册书
      showOpr: true,
      showCommentPanel: false,
      showVipPop: false,
      showCoinPop: false,
      showConfirmCoinPop: false,
      showCommunityShare: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 用户余额
    user({ $store }) {
      return $store.getters['getUserInfo']
    },
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    videoPrice() {
      if (this.isVipMember) {
        return ((this.itemVideoInfo.price * this.itemVideoInfo.movieDiscount) / 10000).toFixed(1)
      } else {
        return this.itemVideoInfo.price / 100
      }
    }
  },
  watch: {
    imgCDN() {
      this.$nextTick(() => {
        this.initSwiper()
      })
    }
  },
  async mounted() {
    //首页短视频（等待接口返回再操作）
    if (this.$route.query.type === 'recommend' || this.$route.query.type === 'topic') {
      await this.getHomeRecommendList()
      this.activeIndex = 0
      this.getVideoInfo(this.list[this.activeIndex].id)
    } else {
      this.list = this.$store.getters['getShortVideoList']
      this.activeIndex = this.$store.getters['getHistoryShortVideoIndex']
      this.getVideoInfo(this.list[this.activeIndex].id)
    }
    if (this.list.length === 0) {
      return this.$toast('暂无视频')
    }
    this.$nextTick(() => {
      // 注册节流方法
      this.throttle = throttle(async () => {
        // 获取当前slide视频节点
        this.player.currentTime = 0
        let videoInfo = this.list[this.activeIndex]
        //第一次不更新
        if (this.list.length > 1) {
          this.getVideoInfo(videoInfo.id)
        }
        //切换
        this.player.src = '/api/app/media/m3u8/' + videoInfo.videoUrl
        this.canPlay = false
        // 为了保证更快速播放，这里主动调用，其实不调用也可以播放，但是他更快速
        let playPromise = this.player.play()
        playPromise
          .then(() => {
            this.player.play()
          })
          .catch(() => {})
        // 取消隐藏控制台
        if (this.controlsDOM) {
          this.controlsDOM.classList.remove('controlHide')
        }
      }, 100)
      if (this.imgCDN) {
        this.initSwiper()
      }
    })
  },

  beforeDestroy() {
    this.list[this.activeIndex].currentTime = this.player.currentTime
    this.$store.dispatch('setShortList', {
      type: 'add',
      item: this.list[this.activeIndex]
    })

    this.swiper = null
    this.throttle = null
    if (this.player) {
      if (this.player.hls) {
        this.player.hls.destroy()
      }
      this.player.src = ''
      this.player.destroy(true)
    }
  },
  methods: {
    goToVip() {
      this.$refs.BuyVipPop.showClosed = true
    },
    goToCoin() {
      this.$refs.BuyGoldPop.showClosed = true
    },
    //显示评论
    closePanel() {
      this.showCommentPanel = false
    },
    // 首页推荐小视频
    async getHomeRecommendList() {
      try {
        let res = {}
        if (this.$route.query.type === 'recommend') {
          res = await homeRecommendList({
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            contentType: 2
          })
        } else if (this.$route.query.type === 'topic') {
          res = await mediaTopic({
            pageSize: this.pageSize,
            pageNum: this.pageNum,
            id: +this.$route.query.id,
            sort: 0,
            mediaType: +this.$route.query.showType == 6 ? 2 : 1
          })
          res.data.shortList = res.data.mediaList
          delete res.data.mediaList
        }
        console.log(res.data, '000')

        if (res.code === 200) {
          if (res.data.shortList && res.data.shortList.length) {
            this.list = this.remove_repeat(this.list, res.data.shortList)
          } else {
            this.list = this.remove_repeat(this.list, res.data.mediaList)
          }
          this.concatSlide(this.list)
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求失败，请稍后再试！')
      }
    },
    //去重
    remove_repeat(oldlist, newlist) {
      // console.log(oldlist,newlist)
      let newShortArr = []
      newlist.forEach(newEle => {
        let status = false
        oldlist.forEach(oldEle => {
          if (newEle.id == oldEle.id) {
            status = true
          }
        })
        if (!status) newShortArr.push(newEle)
      })
      newShortArr = [...oldlist, ...newShortArr]
      return newShortArr
    },
    // 点击观看完整视频
    showBuyPorp() {
      this.player.pause()
      this.buyPorp = true
    },
    // 解除静音
    clickCloseVolume() {
      if (this.player) {
        this.showVolume = true
        this.player.muted = false
      }
    },
    // up主跳转 头像
    clickImg(item) {
      if (item.publisher && item.publisher.id) {
        this.$router.push(`/up/index/${item.publisher.id}`)
      } else {
        this.$toast('发布者id不存在！')
      }
    },
    // 剪贴板
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    // 视频购买成功
    async payBtnFn(type) {
      try {
        const res = await video_pay({
          id: this.itemVideoInfo.id,
          payType: type
        })
        if (res.data.code === 200) {
          this.$toast('解锁成功,正在重新加载...')
          this.playable = true
          this.code = 200
          this.showPayBtn = false
          this.itemVideoInfo.isBuy = true
          this.initPlay()
          this.$store.dispatch('get_userInfo')
        } else {
          this.$toast(res.data.msg)
        }
        this.buyPorp = false
      } catch (error) {
        console.log(error)
        this.buyPorp = false
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 视频标签跳转
    toTagList(item) {
      this.$router.push({
        path: '/home/videoTagDetail',
        query: {
          id: item.id,
          name: item.name
        }
      })
    },
    /**
     * 初始化轮播图
     **/
    initSwiper() {
      const that = this
      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        initialSlide: that.$store.getters['getHistoryShortVideoIndex'], //初始化时第一个显示的slide        roundLengths : true, //防止文字模糊
        roundLengths: true, //防止文字模糊
        runCallbacksOnInit: false,
        followFinger: false,
        // longSwipes: false,
        virtual: {
          cache: true,
          slides: that.list,
          renderExternal: data => {
            // 在渲染虚拟slider时 取获取到具体数据
            that.virtualData = data
          }
        },
        on: {
          // 初始化后播放视频，并且请求下一页数据
          init() {
            that.$nextTick(() => {
              that.initPlay()
            })
            that.$store.dispatch('setShortList', {
              type: 'add',
              item: that.list[that.activeIndex]
            })
          },
          touchEnd(swiper, event) {
            if (swiper.swipeDirection === 'next' && that.activeIndex + 1 === that.list.length) {
              that.$toast('别滑啦，人家已经是最后一个了...')
            }
          },
          slideChangeTransitionEnd() {
            that.slideChangeTransition = false
            that.$nextTick(() => {
              that.throttle()
            })
          },
          slideChangeTransitionStart(swiper) {
            that.slideChangeTransition = true
            if (that.controlsDOM) {
              that.controlsDOM.classList.add('controlHide')
            }
          },
          slidePrevTransitionStart() {
            --that.activeIndex
          },
          async slideNextTransitionStart() {
            // 每次切换都触发这个方法，请求下一页数据
            ++that.activeIndex
            // 根据传入typeTXT判断请求哪个列表
            that.$store.dispatch('setShortList', {
              type: 'add',
              item: that.list[that.activeIndex]
            })
            if (that.activeIndex + 1 === that.list.length) {
              that.pageNum += 1
              if (that.$route.query.type === 'recommend') {
                await that.getHomeRecommendList()
              }
            }
          }
        }
      })
    },
    // 切换数组
    concatSlide(dspList) {
      if (this.swiper) {
        this.swiper.virtual.slides = dspList
        this.swiper.update()
      }
    },
    // 初始化播放器
    async initPlay() {
      const that = this
      // 每次播放做一次请求，获取播放状态
      let videoInfo = this.list[this.activeIndex]
      if (this.player) {
        this.player.destroy(true)
        this.player = null
      }
      this.player = new HlsJsPlayer({
        id: `short-play`, //容器ID
        lang: 'zh-cn',
        autoplay: true, // 是否自动播放
        volume: 0.3, // 音量
        autoplayMuted: true,
        width: window.width,
        height: window.innerHeight,
        closeInactive: true, //使播放器控制栏常驻不隐藏
        loop: false, // 循环
        pic: false, // 画中画
        miniplayer: false, // 迷你播放
        miniplayerConfig: {
          bottom: 40,
          right: 0,
          width: 160,
          height: 90
        },
        // controls: true,
        controlsList: ['nofullscreen'],
        fullscreen: false,
        cssFullscreen: false,
        videoInit: false, // 初始化视频首帧
        lastPlayTime: 0, // 记忆播放，从多少多少秒开始
        lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
        rotateFullscreen: false, // 使得视频全屏幕时 横屏
        download: false, //设置download控件显示
        airplay: false,
        playsinline: true,
        url: '/api/app/media/m3u8/' + videoInfo.videoUrl,
        execBeforePluginsCall: [
          () => {
            console.log('Execute before plugins call')
          }
        ],
        // poster: "http://lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg",
        'x5-video-player-type': 'h5', //微信同层播放：
        'x5-video-player-fullscreen': 'true', //微信全屏播放：
        'x5-video-orientation': 'landscape' //微信横竖屏控制
      })
      // 显示播放loading
      if (this.player) this.player.play()

      this.controlsDOM = document.getElementsByClassName('xgplayer-controls')
      if (this.controlsDOM && this.controlsDOM.length) {
        this.controlsDOM = this.controlsDOM[0]
      }
      this.player.once('canplay', () => {
        this.player.play()
      })
      this.player.on('canplay', function () {
        that.canPlay = true
      })
      // 获取当前视频播放时间
      this.player.on('timeupdate', function () {
        console.log('888')
        // 当不可播放完整视频时，如果播放时长大于等于预览市场，给出购买提示
        if (that.itemVideoInfo.preTime == 0) {
          if ((!that.playable || that.code !== 200) && that.player.currentTime >= 0.1) {
            that.skipPreview()
            that.player.pause()
            that.player.currentTime = 0
          }
        } else {
          if ((!that.playable || that.code !== 200) && that.player.currentTime >= that.itemVideoInfo.preTime) {
            that.skipPreview()
            that.player.pause()
            that.player.currentTime = 0
          }
        }
      })
    },
    // 跳过预览事件
    skipPreview() {
      if (this.itemVideoInfo.payType === 2) {
        if (this.user.balance <= this.videoPrice) {
          this.showCoinPop = true
        } else {
          this.showConfirmCoinPop = true
        }
        return
      } else if (this.itemVideoInfo.payType === 1) {
        this.showVipPop = true
        return
      }
    },
    // 点击slide里最高层级时的逻辑
    handleClickSlideMark() {
      let playPromise = this.player.play()
      if (playPromise) {
        playPromise
          .then(() => {
            if (this.player && this.player.hasStart) {
              if (this.player.paused) {
                this.player.play()
              } else {
                this.player.pause()
              }
            }
          })
          .catch(() => {
            this.player.pause()
          })
      } else {
        return
      }
    },
    // 请求视频播放信息
    async getVideoInfo(id) {
      try {
        const res = await video_play({
          id
        })
        if (res.code === 200) {
          this.code = res.data.code
          this.playable = res.data.playable
          this.itemVideoInfo = res.data.mediaInfo
          this.watchCount = res.data.watchCount
        } else {
          this.$notify('视频已下架或播放错误，请选择其他视频观看!')
        }
      } catch (error) {
        console.log(error)
        this.$notify('视频已下架或播放错误，请选择其他视频观看!')
      }
    },
    // 收藏，喜欢视频
    async addLike(item, type) {
      const res = await collect({
        collectType: item.videoType,
        flag: type == 1 ? !item.isCollect : type == 2 ? !item.isLike : !item.isStampede,
        object_id: item.id,
        type: type //1:收藏 2:点赞 3:踩
      })
      if (res.code === 200) {
        const typeMap = {
          1: { property: 'isCollect', counter: 'collects', successMsg: '收藏成功', failMsg: '收藏取消' },
          2: { property: 'isLike', counter: 'likes', successMsg: '点赞成功', failMsg: '点赞取消' },
          3: { property: 'isStampede', counter: 'stampede', successMsg: '踩踏成功', failMsg: '踩踏取消' }
        }
        if (typeMap.hasOwnProperty(type)) {
          const { property, counter, successMsg, failMsg } = typeMap[type]
          item[property] = !item[property]
          if (this.itemVideoInfo[property]) {
            item[counter] += 1
            return this.$toast(successMsg)
          } else {
            item[counter] -= 1
            return this.$toast(failMsg)
          }
        }
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>
<style scoped src="../../../static/css/swiper.min.css"></style>
<style lang="scss" scoped>
.newPop {
  color: #000000;

  p {
    font-size: 0.32rem;
    text-align: center;
    font-weight: 500;
  }

  .sub-txt {
    font-size: 0.24rem;
    font-weight: 400;
  }

  .btn {
    @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
    margin: 0.3rem;
    justify-content: center;

    div {
      width: 1.8rem;
      height: 0.56rem;
      background: $btnBg;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      font-size: 0.28rem;
      color: #fff;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  .tip-txt {
    font-size: 0.24rem;
    text-align: center;
  }
}

:deep() {
  //普通播放页
  .xgplayer-progress-outer {
    margin-top: 0;
    height: 3px;
    margin-top: 8.5px;
  }

  .xgplayer-skin-default .xgplayer-controls {
    height: 0;
    position: fixed;
    // bottom: calc(1.1rem + env(safe-area-inset-bottom));
    // bottom: calc(1.1rem + constant(safe-area-inset-bottom));
  }
}

//首页，专用内置样式
.home-page-video-style {
  :deep() {
    .xgplayer-skin-default .xgplayer-controls {
      height: 0;
      position: fixed;
      bottom: calc(0.7rem + env(safe-area-inset-bottom));
      bottom: calc(0.7rem + constant(safe-area-inset-bottom));
    }

    .xgplayer-placeholder,
    .xgplayer-volume,
    .xgplayer-play,
    .xgplayer-fullscreen,
    .xgplayer-volume {
      display: none;
    }

    .xgplayer-skin-default .xgplayer-progress {
      margin: 0 auto;
      width: 4.32rem;
      top: auto;
      height: 0.4rem;
      line-height: 0.4rem;
      position: fixed;
      bottom: calc(0.5rem + constant(safe-area-inset-bottom));
      bottom: calc(0.5rem + env(safe-area-inset-bottom));
    }

    .xgplayer-progress-outer {
      margin-top: 0;
      height: 3px;
      margin-top: 8.5px;
    }

    .xgplayer-icon {
      .xgplayer-icon-play,
      .xgplayer-icon-muted,
      .xgplayer-icon-pause {
        display: none !important;
      }
    }

    .xgplayer-fullscreen,
    .xgplayer-enter {
      display: none !important;
    }

    .xgplayer-time {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: $pcMaxWidth;
      height: 0;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
      align-items: center;

      span {
        &:nth-child(1) {
        }

        &:nth-child(2) {
        }

        &::after {
          display: none;
          content: none;
        }
      }
    }
  }
}

.sort-play {
  padding: 0;

  .comment-box {
    padding: 0 0.32rem 1.34rem;

    :deep() {
      .content-main-wrap {
        height: 6.5rem !important;
      }
    }
  }
}

#short-play {
  :deep() {
    .xgplayer-enter-spinner {
      width: 1rem;
      height: 1rem;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  max-width: $pcMaxWidth;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0.18rem;
  color: #fff;
  /* Center slide text vertically */
}

.bg-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #000;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.slide-mark {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
}

.volume-tip {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  left: 0.2rem;
  top: 2rem;
  padding: 0 0.15rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.2rem;
  background-color: rgba($color: #dbd1d1, $alpha: 0.65);
  color: rgb(121, 23, 23);
  font-size: 0.22rem;
}

:deep() {
  video {
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: #000000;
  }

  .xgplayer-skin-default {
    background-color: transparent;
  }

  .controlHide {
    visibility: hidden;
    opacity: 0;
  }

  #ShortVideoPage {
    position: fixed;
    top: 0;
  }
}

// 右侧三个图标
.right-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0.2rem;
  bottom: 1.8rem;
  z-index: 999;
  font-size: 0.24rem;

  &-header {
    @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
    margin-bottom: 0.6rem;
    position: relative;
    width: 0.7rem;
    height: 0.7rem;

    :deep() {
      .warp {
        border-radius: 50%;
      }

      img {
        border-radius: 50%;
      }
    }

    .add-icon {
      position: absolute;
      width: 0.25rem;
      height: 0.25rem;
      bottom: -0.12rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &-comment,
  &-share,
  &-likes {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.4rem;

    img {
      width: 0.48rem;
      height: 0.48rem;
    }
  }
  .right-icon-fold {
    width: 0.55rem;
    height: 0.55rem;
    img {
      width: 100%;
    }
  }
}

// 短视频描述
.video-info {
  position: absolute;
  left: 0.2rem;
  bottom: calc(1.6rem + constant(safe-area-inset-bottom));
  bottom: calc(1.6rem + env(safe-area-inset-bottom));
  max-width: 5.2rem;
  display: flex;
  flex-direction: column;
  font-size: 0.24rem;
  z-index: 2;

  .buy-vip {
    width: 3rem;
    height: 0.5rem;
    background: linear-gradient(0deg, rgba(247, 85, 85, 0.12), rgba(247, 85, 85, 0.12)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    border-radius: 0.48rem;
    @include flex-center;
    color: #f75555;
    font-size: 0.24rem;
  }

  .pay-coin {
    padding: 0 0.24rem;
    height: 0.5rem;
    background: linear-gradient(0deg, rgba(250, 204, 21, 0.12), rgba(250, 204, 21, 0.12)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    border-radius: 0.48rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #facc15;
    font-size: 0.24rem;
  }

  .publisher-info {
    display: flex;
    align-items: center;

    .pay-btn {
      margin-left: 0.1rem;
      height: 0.4rem;
      background: $btnBg;
      color: #fff;
      border-radius: 0.2rem;
      padding: 0.1rem 0.15rem;
      display: flex;
      align-items: center;
      white-space: nowrap;

      .price {
        display: flex;
        align-items: center;
        font-size: 0.3rem;
        padding-right: 0.06rem;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin: 0 0.1rem;
        }
      }
    }
  }

  .video-title {
    text-align: left;
  }

  .tags-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.16rem;

    span {
      padding: 0.02rem 0.13rem;
      background: rgba($color: #000, $alpha: 0.3);
      margin-right: 0.15rem;
      margin-top: 0.15rem;
      display: inline-block;
      border-radius: 0.08rem;
    }
  }
}

// 已关注标签
// .isFollow {

//   padding: 0.03rem 0.15rem;
//   border: 0.02rem solid #fff;
//   border-radius: 0.1rem;
//   margin-top: 0.2rem;
//    color: #755542;
// }
.desc {
  font-size: 0.28rem;
}

.freeTimes {
  line-height: 0.54rem;
  padding: 0 0.16rem;
  border-radius: 0.12rem;
  background: rgba(0, 0, 0, 0.6);
  font-size: 0.24rem;
  color: #fff;
  position: fixed;
  top: 0.46rem;
  right: 0.32rem;
  z-index: 2012;

  span {
    color: #ff1e1e;
  }
}

// 购买提示框
.buy-porp {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  max-width: $pcMaxWidth;
  background: rgba($color: #000, $alpha: 0.8);
  @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
  color: #fff;
  font-size: 0.32rem;

  .no-times {
    text-align: center;
    z-index: 9999;

    .btn {
      @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
      max-width: 4rem;
      margin: 0 auto;
    }

    span:first-child {
      background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
      color: #fff;
      margin-right: 0.2rem;
    }

    span {
      width: 1.8rem;
      line-height: 0.56rem;
      font-size: 0.28rem;
      border-radius: 0.32rem;
    }

    span:last-child {
      margin-left: 0.2rem;
      border: solid 0.02rem #00f6f8;
    }

    .sub-txt {
      color: rgba($color: #fff, $alpha: 0.5);
      font-size: 0.24rem;
      margin: 0.26rem 0;
    }

    .tip-txt {
      font-size: 0.2rem;
      margin-top: 0.2rem;

      i {
        margin-left: 0.1rem;
        color: $btnBg;
        display: inline-block;
        font-style: normal;
      }
    }
  }
}

// 金币购买弹窗
</style>
