<template>
  <div class="postDetails">
    <!-- 帖子列表 -->
    <div class="desc-txt">
      <div class="title_box">
        <span v-if="newData.base.recommend" class="at">置顶</span>
        <span class="title">{{ newData.base.title }}</span>
      </div>

      <div v-if="newData.node.text">
        <p class="desc">
          {{ newData.node.text }}
        </p>
        <div class="desc_btn" @click.stop="toDetail(newData)">...阅读全文</div>
      </div>
    </div>
    <!-- 图片+video展示最多显示2排 -->
    <div class="img-list">
      <!-- 历史记录item与其他记录item字段不一致 -->
      <template v-if="newData.node && newData.node.imgs">
        <EDecryptImg
          v-for="(img, index) in newData.node.imgs"
          :key="index"
          :img-c-d-n="imgCDN"
          class="img-item"
          :need-padding="false"
          :img-u-r-l="img"
          :class="newData.node.imgs.length === 1 && !newData.base.videoCover ? 'oneImg-item' : ''"
        />
      </template>
      <EDecryptImg
        v-if="newData.base.videoUrl"
        :img-c-d-n="imgCDN"
        class="img-item img-video"
        :img-u-r-l="newData.base.videoCover"
        :class="!newData.node.imgs.length ? 'oneImg-item' : ''"
      >
        <div class="play">
          <img src="@/assets/imgs/play.svg" alt="" />
        </div>
      </EDecryptImg>
      <i></i>
    </div>
    <img-preview v-if="showImgPreview" v-model="imgIndex" :new-data="newData" />
  </div>
</template>
<script>
export default {
  components: {
    ImgPreview: () => import('@/components/Community/ImgPreview.vue')
  },
  filters: {
    nickNamef(v, tabList) {
      for (let index = 0; index < tabList.length; index++) {
        const element = tabList[index]
        if (v == element.id) {
          return element.name
        }
      }
    }
  },
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isDetail: {
      type: Boolean,
      default() {
        return false
      }
    },
    showType: {
      type: Number,
      default: () => {
        return 0 //0:帖子列表 1:帖子详情
      }
    }
  },
  data() {
    return {
      newData: {},
      showImgPreview: false,
      imgIndex: 0
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMemberAndNotShop() {
      return (!this.newData.base.price && !this.newData.base.isBought && !this.isMember) || this.userInfo.vipType == 11
    }
  },
  watch: {
    itemData: {
      handler(n) {
        this.newData = {
          ...n,
          node: Array.isArray(n.nodes) ? n.nodes[0] : n.node && typeof n.node === 'object' ? n.node : undefined
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // 判断是否显示展开收起按钮
    // if (this.newData.node && this.newData.node.text && !this.isDetail) {
    //   this.ifOver = this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight;
    // }
  },

  methods: {
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}`)
    },
    // 图片预览
    clickImg(index) {
      if (this.isDetail) {
        this.showImgPreview = true
        this.imgIndex = index
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.notice-coin {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  @include flex-column-center;
  > :nth-child(1) {
    font-size: 0.4rem;
    font-weight: 500;
    padding: 0 0.2rem;
    line-height: 0.78rem;
    > .count {
      color: #ff8f4c;
      padding: 0 0.16rem;
    }
  }
  > :nth-child(2) {
    margin-top: 0.32rem;
    font-size: 0.28rem;
    line-height: 0.4rem;
  }
  > :nth-child(3) {
    margin-top: 0.32rem;
    @include flex-center;
    width: 2.72rem;
    height: 0.76rem;
    border-radius: 0.44rem;
    font-size: 0.32rem;
    background: $gradientBg;
  }
}
.notice-vip {
  position: absolute;
  width: 100%;
  height: 100%;
  @include flex-center;
  color: #fff;
  > :nth-child(1) {
    margin-top: 0.32rem;
    @include flex-center;
    width: 2.72rem;
    height: 0.76rem;
    border-radius: 0.44rem;
    font-size: 0.28rem;
    background: $gradientBg;
  }
}

.postDetails {
  margin-bottom: 0.1rem;

  .desc-txt {
    font-size: 0.28rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;

    .at {
      padding: 0.04rem 0.2rem;

      border-radius: 0.06rem;
      background: #ffb267;
      margin-right: 0.13rem;
      font-size: 0.24rem;
      color: #333;
      white-space: nowrap;
    }

    .categoryType {
      color: #1d93d6;
      margin-right: 0.05rem;
    }

    .title_box {
      @include flexbox($jc: flex-start, $ai: flex-start);
      @include textoverflow(2);
    }

    .title {
      font-size: 0.32rem;
    }

    .desc {
      margin-top: 0.24rem;
      @include textoverflow(3);
    }

    .desc_btn {
      color: #ffcf86;
    }
  }

  .details-txt {
    font-size: 0.26rem;
    white-space: pre-line;

    &.details-title {
      font-size: 0.36rem;
      margin-bottom: 0.16rem;
      padding-bottom: 0.28rem;
    }

    .categoryType {
      color: #1d93d6;
      margin-right: 0.05rem;
    }
  }

  .details-video {
    position: relative;
    width: 6.78rem;
    height: 3.82rem;
    .play {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .img-pack {
    margin: 0.2rem 0;
  }

  .img-list {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
    margin: 0.14rem 0 0.2rem 0;
    .img-video {
      .play {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 0.6rem;
          height: 0.6rem;
        }
      }
    }
    .img-item {
      margin-top: 0.1rem;
      border-radius: 0.1rem;
      overflow: hidden;
      width: 2.22rem;
      height: 2.22rem;
      margin-right: 0.1rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    i {
      width: 2.26rem;
    }

    .img-item:nth-child(3n) {
      margin-right: 0;
    }
  }

  .video-cover {
    margin: 0 auto;

    .video-desc {
      font-size: 0.3rem;
      max-height: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: pre-wrap;
    }

    .video-pack {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.2rem 0;

      .video-l {
        min-width: 2.74rem;
        max-width: 2.74rem;
        height: 1.54rem;
        margin-right: 0.2rem;
        border-radius: 0.08rem;
        overflow: hidden;

        ::v-deep {
          .vide_detail {
            height: 0.3rem;
            font-size: 0.18rem;
            padding: 0 0.1rem;
          }

          .video_image {
            height: 100%;
          }
        }
      }

      .video-r {
        font-size: 0.2rem;
        color: #333;
      }
    }

    .sm-video {
      position: relative;

      ::v-deep {
        .warpNoPadding {
          img {
            object-fit: contain;
            max-height: 6.9rem;
          }
        }
      }

      .van-icon-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.oneImg-item {
  width: 100% !important;
  height: auto !important;
  :deep(img) {
    object-fit: contain !important;
    max-height: 3.45rem !important;
  }
  margin-right: 0 !important;
}
:deep() .btn-wrap {
  > div:nth-child(2) {
    background: red;
  }
}

.node_text {
  @include textoverflow(4);
  white-space: pre-line;
  color: #999;
}
</style>
