var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.itemList && _vm.itemList.length)?_c('div',{staticClass:"sixListChange"},[_c('HomeTitle',{attrs:{"title":_vm.name,"path":`/acg/acgTopicDetail/?title=${_vm.name}&type=cartoon&id=${_vm.id}`}}),_c('ul',{staticClass:"nine-list"},_vm._l((_vm.videoList.length ? _vm.videoList : _vm.itemList),function(item){return _c('li',{key:item.id},[_c('cartoonOneCard',{attrs:{"width":"3.36rem","height":"1.88rem","itemData":item}})],1)}),0),_c('div',{staticClass:"footerBtn"},[_c('div',{on:{"click":function($event){return _vm.$router.push({
          path: `/acg/acgTopicDetail/`,
          query: {
            id: _vm.id,
            name: _vm.name,
            type: 'cartoon'
          }
        })}}},[_c('img',{attrs:{"src":require("assets/imgs/home/more2.png"),"alt":""}}),_vm._v(" 更多片源 ")]),_c('div',{on:{"click":_vm.fn_videoTopicChange}},[_c('img',{attrs:{"src":require("assets/imgs/home/reload.png"),"alt":""}}),_vm._v(" 更换一批 ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }