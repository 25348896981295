<template>
  <div class="item" @click="toPlay(data)">
    <div class="cover">
      <ESCVideoCover class="item-bg" :imgURL="data.coverImg" :videoURL="videoURL">
        <!-- 视频分类标签 -->
        <!-- <VideoType :data="data"></VideoType> -->
      </ESCVideoCover>
      <VideoCardMask :data="data"></VideoCardMask>
      <slot name="mask" :data="data"></slot>
    </div>
    <VideoCardFooter :data="data"></VideoCardFooter>
  </div>
</template>

<script>
export default {
  components: {
    // VideoType: () => import('@/components/VideoType.vue'),
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  inject: ['getVideoPreview'],
  props: ['data'],
  data() {
    return {
      videoPath: '/api/app/media/m3u8pre/'
    }
  },
  computed: {
    isVideoPreview() {
      return this.getVideoPreview && this.getVideoPreview()
    },
    token({ $store }) {
      return $store.state.user.token
    },
    videoURL() {
      return ''
      if (!this.isVideoPreview) return ''
      if (this.data.preVideoUrl) {
        return this.videoPath + this.data.id + '?token=' + this.token
      } else {
        return ''
      }
    }
  },

  methods: {
    toPlay(data) {
      if (data) {
        this.$router.push(`/play/longVideo/${data.id}`)
      }
    },
    clickTag(item) {
      if (item.id) {
        this.$router.push({
          path: '/home/videoTagDetail',
          query: {
            id: item.id,
            name: item.name
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 3.36rem;
  height: 1.46 * 2rem;
  background-color: #f7f7f7;
  margin-bottom: 0.2rem;

  .cover {
    width: 3.36rem;
    height: 1.88rem;
    border-radius: 0.06rem 0.06rem 0 0;
    overflow: hidden;
    position: relative;

    .item-bg {
      height: 100%;
    }
  }
}
</style>
